import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch from "../../utils/axios";
import { toast } from "react-toastify";
import { IGetSingleClient } from "../../types/clients.interface";
import { clientState } from "../../views/Clients/data";
import { handleErrors } from "../../utils/helpers";


const initialState:IState = {
  isLoading: false,
  clients: [],
  singleClient:null
};

interface IState{
  isLoading:boolean
  clients:IGetSingleClient[]
  singleClient:IGetSingleClient
}

export const getClients = createAsyncThunk(
  "clients/getAll",
  async (_, thunkApi) => {
    try {
      const resp = await customFetch.get("client");
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteClient = createAsyncThunk(
  "clients/delete",
  async (id:number, thunkApi) => {
    try {
       await customFetch.delete("client/"+id);
      return id;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);


export const editClient = createAsyncThunk(
  "clients/edit",
  async (client:IGetSingleClient, thunkApi) => {
    try {
      const resp = await customFetch.patch("client/"+client.id, client);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const addClient = createAsyncThunk(
  "clients/add",
  async (client:clientState, thunkApi) => {
    try {
      const resp = await customFetch.post("client", client);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getSingleClient = createAsyncThunk(
  "clients/getSingle",
  async (id:string, thunkApi) => {
    try {
      const resp = await customFetch.get("client/"+id,);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    clearClients:(state:IState)=>{
      state.clients = []
      state.singleClient = null
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getClients.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClients.fulfilled, (state, { payload }: any) => {
        state.isLoading = false;
        state.clients = payload
      })
      .addCase(getClients.rejected, (state, {payload}: any) => {
        state.isLoading = false;
        handleErrors(payload)
      })
      .addCase(deleteClient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteClient.fulfilled, (state, { payload }: any) => {
        state.clients = state.clients.filter(c=>c.id!==payload)
        toast.success('Klijent uspešno obrisan.')
        state.isLoading = false;
      })
      .addCase(deleteClient.rejected, (state, {payload}: any) => {
        state.isLoading = false;
        handleErrors(payload)
      })
      .addCase(addClient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addClient.fulfilled, (state, { payload }: any) => {
        state.isLoading = false;
        state.clients.push(payload)
        toast.success('Klijent uspešno dodat.')
      })
      .addCase(addClient.rejected, (state, {payload}: any) => {
        state.isLoading = false;
        handleErrors(payload)
      })
      .addCase(getSingleClient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSingleClient.fulfilled, (state, { payload }: any) => {
        state.isLoading = false;
        state.singleClient = payload
      })
      .addCase(getSingleClient.rejected, (state, {payload}: any) => {
        state.isLoading = false;
        handleErrors(payload)
      })
      .addCase(editClient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editClient.fulfilled, (state: any) => {
        state.isLoading = false;
        toast.success('Klijent uspešno izmenjen.')
      })
      .addCase(editClient.rejected, (state, {payload}: any) => {
        state.isLoading = false;
        handleErrors(payload)
      })
  },
});

export const {clearClients} = clientsSlice.actions

export default clientsSlice.reducer;
