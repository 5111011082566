import React, { useEffect, } from 'react'
import '../sass/main.scss'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Nav } from '../components/Nav'
import { TopBar } from '../components/TopBar'



export const SharedLayout = () => {

    const location = useLocation()
    const navigate = useNavigate()


    useEffect(() => {
        if (location.pathname === '/') {
            navigate('requests')
        }
    }, [])

    return (
        <div className='layout-wrapper'>
            <Nav />
            <div className='dashboard-container'>
                <TopBar />
                <Outlet />
            </div>
        </div>
    )
}
