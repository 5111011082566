import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import AsyncSelect, { IAgenciesSelectValue, IAsyncSelectValue } from "../../components/AsyncSelect"
import { Button } from "../../components/Button"
import DatePicker from "../../components/DatePicker"
import { Input } from "../../components/Input"
import Select, { ISelectValue } from "../../components/Select"
import { TextArea } from "../../components/TextArea"
import { TextDisplay } from "../../components/TextDisplay"
import { TextDisplayButton } from "../../components/TextDisplayButton"
import { saveReservation } from "../../features/reservations/reservationsSlice"
import { getRentPeriods } from "../../features/side-data/sideDataSlice"
import { AppDispatch } from "../../store"
import { IGetSingleAgency } from "../../types/agencies.interface"
import { IGetSingleVehicle } from "../../types/vehicles.interface"
import { cities, pickupReturnMethods } from "../../utils/data"
import { changeState, daysBetweenDates } from "../../utils/helpers"
import { standardFieldValidation, validateDate, validateSelect } from "../../utils/validationUtils"
import { initialState, invalidReservationsFields } from "./data"
import { ReservationsAdditionalServicesModal } from "./ReservationsAdditionalServicesModal"

export const AddReservation = () => {

    const [state, setState] = useState(initialState)
    const dispatch = useDispatch<AppDispatch>()
    const [invalidFields, setInvalidFields] = useState(invalidReservationsFields)
    const [pricePerDay, setPricePerDay] = useState(0)
    const [price, setPrice] = useState(0)
    const [modal, setModal] = useState(false)
    const navigate = useNavigate()

    console.log(invalidFields)

    const urlParams = new URLSearchParams(window.location.search)

    useEffect(() => {
        if (urlParams.toString().length > 0) {
            setState(prev => {
                const copy = structuredClone(prev)
                copy.firstName = urlParams.get('firstName')
                copy.lastName = urlParams.get('lastName')
                copy.phoneNumber = urlParams.get('phoneNumber')
                copy.email = urlParams.get('email')
                copy.toDate = new Date(urlParams.get('toDate'))
                copy.fromDate = new Date(urlParams.get('fromDate'))
                return copy
            })
            setInvalidFields(prev => prev.filter(f => !['firstName', 'lastName', 'phoneNumber', 'email', 'toDate', 'fromDate'].includes(f)))
        }
    }, [])


    useEffect(() => {
        dispatch(getRentPeriods())
    }, [])

    useEffect(() => {

        try {
            if (state.toDate && state.fromDate && state.agency && state.vehicle) {
                const ppd = calculateVehiclePrice()
                setPricePerDay(ppd)
                setPrice(ppd * getDaysInside() + state.additionalServicesSumTotal)
            }
        } catch (error) {
            console.log(error)
        }


    }, [state.toDate, state.fromDate, state.agency, state.vehicle])


    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value, name } = e.target
        standardFieldValidation(e, setInvalidFields)
        setState(prev => {
            prev[name] = value
            return prev
        })
    }

    const handleSelect = (v: ISelectValue | IAsyncSelectValue | IAgenciesSelectValue, name: string) => {

        validateSelect(v, name, setInvalidFields)
        setState(prev => {

            if (name === 'vehiclePickupMethod') {
                switch (v.value) {
                    case 1:
                        setInvalidFields(prev => prev.filter(f => !['vehiclePickupCity', 'vehiclePickupAddress'].includes(f)))
                        break;
                    case 2:
                        setInvalidFields(prev => [...prev, 'vehiclePickupCity', 'vehiclePickupAddress'])
                        break;
                    case 3:
                        setInvalidFields(prev => [...prev.filter(f => !(f === 'vehiclePickupAddress')), 'vehiclePickupCity'])
                        break
                }
                prev.vehiclePickupCity = { value: '', label: '' }
                prev.vehiclePickupAddress = ''

            } else if (name === 'vehicleReturnMethod') {
                switch (v.value) {
                    case 1:
                        setInvalidFields(prev => prev.filter(f => !['vehicleReturnCity', 'vehicleRetrunAddress'].includes(f)))
                        break;
                    case 2:
                        setInvalidFields(prev => [...prev, 'vehicleReturnCity', 'vehicleReturnAddress'])
                        break;
                    case 3:
                        setInvalidFields(prev => [...prev.filter(f => !(f === 'vehicleReturnAddress')), 'vehicleReturnCity'])
                        break
                }
                prev.vehicleReturnCity = { value: '', label: '' }
                prev.vehicleReturnAddress = ''
            } else if (name === 'agency') {
                prev.additionalServices = (v as IAgenciesSelectValue).additionalServices
            }

            prev[name] = v
            return prev
        })
    }


    const handleDate = (v: Date, name: string) => {
        validateDate(name, setInvalidFields)
        changeState(setState, name, v)
    }

    const handlePricePerDay = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPricePerDay(e.target.valueAsNumber)
        setPrice(e.target.valueAsNumber * getDaysInside() + state.additionalServicesSumTotal)
    }

    const handleSave = async (shouldAccept: boolean) => {
        if (invalidFields.length > 0) {
            toast.warn('Sva polja moraju biti popunjena.')
            return
        }
        const resp = await dispatch(saveReservation({ reservation: { ...state, price }, shouldAccept }))
        if (resp.meta.requestStatus === 'fulfilled') {
            navigate('/reservations')
        }
    }

    const getDaysInside = () => daysBetweenDates(state.fromDate, state.toDate)


    const calculateVehiclePrice = () => {
        const startDate = state.fromDate
        const endDate = state.toDate

        startDate?.setFullYear(2024)
        endDate?.setFullYear(2024)

        const daysInside = getDaysInside()

        const season = state.agency.seasons.find(s => {
            const newFromDate = new Date(s.fromDate)
            const newToDate = new Date(s.toDate)
            newFromDate.setFullYear(2024)
            newToDate.setFullYear(2024)
            return newFromDate <= startDate
        })

        try {
            if (!season) {
                return state.vehicle.vehicleAgencySeasonRentPeriodPrices.find(rp => rp.rentPeriod.from <= daysInside && rp.rentPeriod.to >= daysInside).price
            }
            return state.vehicle.vehicleAgencySeasonRentPeriodPrices.find(rp => rp.rentPeriod.from <= daysInside && rp.rentPeriod.to >= daysInside && (rp.agencySeason ? rp.agencySeason.id === season.id : season.id == null)).price
        } catch (error) {
            console.log(error)
            return 0
        }

    }

    const getFullVehiclePrice = () => {
        const daysInside = getDaysInside()
        const price = pricePerDay
        return daysInside * price
    }

    const openModal = () => {
        setModal(true)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }



    return <div className="form-page" >
        <h3 className="form-subtitle" >KLIJENT</h3>
        <div className="form-grid" >
            <Input width='100%' label="Ime" name="firstName" handleChange={handleChange} value={state.firstName} />
            <Input width='100%' label="Prezime" name="lastName" handleChange={handleChange} value={state.lastName} />
            <Input width='100%' label="Broj telefona" type="tel" name="phoneNumber" handleChange={handleChange} value={state.phoneNumber} />
            <Input width='100%' label="Email" name="email" type="email" handleChange={handleChange} value={state.email} />
            <Input width='100%' label="Br. dozvole" name="license" handleChange={handleChange} value={state.license} />
        </div>
        <h3 className="form-subtitle" >REZERVACIJA</h3>
        <div className="form-grid">
            <DatePicker width='100%' value={state.fromDate} label="Datum od" handleChange={handleDate} name='fromDate' />
            <DatePicker minDate={state.fromDate} width='100%' value={state.toDate} label="Datum do" handleChange={handleDate} name='toDate' />
            {<TextDisplay label="Ukupno dana" text={daysBetweenDates(state.fromDate, state.toDate)} />}
            <Select value={state.vehiclePickupMethod} width='100%' label="Način preuzimanja" options={pickupReturnMethods} handleChange={handleSelect} name='vehiclePickupMethod' />
            <Select value={state.vehiclePickupCity} width='100%' label="Grad" options={cities} handleChange={handleSelect} name='vehiclePickupCity' invisible={!state.vehiclePickupMethod.value || state.vehiclePickupMethod.value === 1} />
            <Input width='100%' label="Adresa" name="vehiclePickupAddress" handleChange={handleChange} value={state.vehiclePickupAddress} invisible={state.vehiclePickupMethod.value !== 2} />
            <Select value={state.vehicleReturnMethod} width='100%' label="Način vraćanja" options={pickupReturnMethods} handleChange={handleSelect} name='vehicleReturnMethod' />
            <Select value={state.vehicleReturnCity} width='100%' label="Grad" options={cities} handleChange={handleSelect} name='vehicleReturnCity' invisible={!state.vehicleReturnMethod.value || state.vehicleReturnMethod.value === 1} />
            <Input width='100%' label="Adresa" name="vehicleReturnAddress" handleChange={handleChange} value={state.vehicleReturnAddress} invisible={state.vehicleReturnMethod.value !== 2} />
        </div>
        <TextArea handleChange={handleChange} name='note' value={state.note} label="Napomena" />
        <h3 className="form-subtitle" >VOZILO</h3>
        <div style={{ display: 'flex', gap: '1rem', marginBottom: '2rem' }} >
            <AsyncSelect top parserFunction={(a: IGetSingleAgency[]) => a.map(a => ({ label: a.name, value: a.id, seasons: a.agencySeasons, additionalServices: a.agencyAdditionalServices.map(s => ({ ...s, checked: false })) }))} value={state.agency} label="Agencija" name="agency" handleChange={handleSelect} link='agencies' />
            <AsyncSelect invisible={!state.agency.value} additionalParams={{ agencyId: state.agency.value }} top parserFunction={(v: IGetSingleVehicle[]) => v.map(v => ({ label: v.brand.name + ' ' + v.model.name, value: v.id, vehicleAgencySeasonRentPeriodPrices: v.vehicleAgencySeasonRentPeriodPrices }))} value={state.vehicle} label="Vozilo" name="vehicle" handleChange={handleSelect} link='vehicle' />
            <Input invisible={!state.vehicle.value} name="vehiclePerDay" label="Cena po danu" type="number" value={pricePerDay} handleChange={handlePricePerDay} />
            <TextDisplay invisible={!state.vehicle.value} text={getFullVehiclePrice() + ' Eur'} label="Ukupna cena vozila" />
        </div>
        <h3 className="form-subtitle" >DODACI</h3>
        <div style={{ display: 'flex', gap: '2rem', marginBottom: '5rem' }} >
            <TextDisplayButton width="15rem" checked={state.additionalServices.some(as => as.checked)} label="Izaberi dodatke" text="Lista dodataka" onClick={openModal} />
            <TextDisplay width="10rem" text={state.additionalServicesSumTotal} label="Cena dodatka" />
        </div>
        <div className="page-bottom" style={{ justifyContent: 'space-between' }} >
            <h2>{price + ' EUR'}</h2>
            <div>
                <Button text="Sačuvaj" onClick={() => handleSave(false)} />
                <Button text="Potvrdi i sačuvaj" style={{ backgroundColor: ' #13B47A' }} onClick={() => handleSave(true)} />
            </div>
        </div>
        {modal && <ReservationsAdditionalServicesModal setInvalidFields={setInvalidFields} additionalServicesSumTotal={state.additionalServicesSumTotal} closeModal={() => setModal(false)} additionalServices={state.additionalServices} setState={setState} />}
    </div>
}