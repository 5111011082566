import React, { SetStateAction, useState } from "react"
import AsyncSelect from "../../components/AsyncSelect"
import { Button } from "../../components/Button"
import { Input } from "../../components/Input"
import { ISelectValue } from "../../components/Select"
import { IAgencyRentPeriodMileage } from "../../types/agencies.interface"
import { IGetSingleRentPeriod } from "../../types/rent-periods.interface"
import { formatStateSeasonRP } from "../../utils/helpers"
import { IVehiclesState, IVehicleStateSeason } from "./data"

export const SeasonsModal = ({ closeModal, seasonId, setState, seasons, rentPeriods }: ISeasonsModal) => {

    const season = seasons.find(s => s.seasonId === seasonId)
    const [seasonRentPeriods, setSeasonRentPeriods] = useState(formatStateSeasonRP(season.rentPeriods, rentPeriods))
    console.log(seasonRentPeriods)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { valueAsNumber, name } = e.target
        setSeasonRentPeriods(prev => {
            const copy = structuredClone(prev)
            copy[Number(name)].price = valueAsNumber
            return copy
        })
    }

    const handleSave = () => {
        setState(prev => {
            const copy = structuredClone(prev)
            const index = copy.seasons.findIndex(s => s.seasonId === seasonId)
            copy.seasons[index].rentPeriods = seasonRentPeriods.filter(rp => rp.rentPeriod).map(rp => {

                const fromTo = (rp.rentPeriod.label as string).split('-')

                return {
                    id: rp.id,
                    from: Number(fromTo[0]),
                    to: Number(fromTo[1]),
                    price: rp.price,
                    prevId: rp.prevId,
                    index: rp.index
                }
            })
            return copy
        })
        closeModal()
    }

    const handleSelect = (value: ISelectValue, index: number) => {
        setSeasonRentPeriods(prev => {
            const copy = structuredClone(prev)
            copy[index].rentPeriod = value
            copy[index].id = Number(value.value)
            return copy
        })
    }

    const renderRows = () => {
        return seasonRentPeriods.map((rp, k) => {

            return <div key={rp?.rentPeriod?.value} className="mileage-modal__row">
                <div className="mileage-modal__row--title">
                    {k === 0 && <h3 className="form-subtitle">Dani</h3>}
                    <AsyncSelect width="285px" handleChange={(v) => handleSelect(v, rp.index)} parserFunction={(data: IGetSingleRentPeriod[]) => data.filter(d => !seasonRentPeriods.some(r => r.id === d.id)).map(d => ({ value: d.id, label: d.from + '-' + d.to }))} link="rent-period" value={rp.rentPeriod} name='rentPeriod' />
                </div>
                <p
                    style={{
                        marginTop: k === 0 ? "1rem" : "-1rem",
                        fontFamily: "Arial",
                        color: "white",
                    }}
                >
                    __
                </p>
                <div className="mileage-modal__row--title">
                    {k === 0 && <h3 className="form-subtitle">Cena</h3>}
                    <Input width="285px" value={rp?.price} type="number" name={String(rp.index)} handleChange={handleChange} />
                </div>
            </div>
        }
        )
    }


    return <div className="mileage-modal">
        <h3 className="form-subtitle">Cene: {season.name}</h3>
        {renderRows()}
        <Button onClick={handleSave} text="Sačuvaj" />
    </div>
}

interface ISeasonsModal {
    closeModal: () => any
    seasonId: number
    setState: React.Dispatch<SetStateAction<IVehiclesState>>
    seasons: IVehicleStateSeason[]
    rentPeriods?: IAgencyRentPeriodMileage[]
}

export interface ISeasonRentPeriod {
    index: number
    rentPeriod: ISelectValue
    price: number
    prevId?: number
}