import React, { SetStateAction, useState } from "react";
import { Button } from "../../components/Button";
import { CheckText } from "../../components/CheckText";
import { Input } from "../../components/Input";
import { TextDisplay } from "../../components/TextDisplay";
import { IAgencyAdditionalService } from "../../types/agencies.interface";
import { standardFieldValidation } from "../../utils/validationUtils";
import { IReservationsState } from "./data";

export const ReservationsAdditionalServicesModal = ({
    additionalServices,
    setState,
    closeModal,
    additionalServicesSumTotal,
    setInvalidFields,
}: IReservationsAdditionalServicesModal) => {
    const [localState, setLocalState] = useState(additionalServices);
    const [sumTotal, setSumTotal] = useState(additionalServicesSumTotal);
    const handleSave = () => {

        setState(prev => {
            prev.additionalServices = localState;
            prev.additionalServicesSumTotal = sumTotal;
            return prev
        })
        closeModal();
        window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSumTotal(e.target.valueAsNumber);
        standardFieldValidation(e, setInvalidFields);
    };

    const handleCheck = (id: string) => {
        setLocalState((prev) => {
            const copy = structuredClone(prev);
            const index = copy.findIndex((as) => String(as.id) === id);
            if (copy[index].checked) {
                copy[index].checked = false;
            } else {
                copy[index].checked = true;
            }
            return copy;
        });
    };

    const renderInputs = () => {
        return localState.map((as) => {
            return (
                <div style={{ display: "flex", justifyContent: "space-between", width: '30%' }}>
                    <CheckText
                        key={as.id}
                        handleCheck={handleCheck}
                        checked={Boolean(as.checked)}
                        id={String(as.id)}
                        name={as.additionalService.name}
                    />
                    <TextDisplay text={as.price} width='50px' />
                </div>
            );
        });
    };

    return (
        <div className="additional-services-modal">
            <div className="additional-services-modal__content">
                <h3 className="form-subtitle">Dodaci</h3>
                <div className="additional-services-grid">{renderInputs()}</div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "1rem",
                        alignItems: "end",
                    }}
                >
                    <Input
                        width="10rem"
                        type="number"
                        name="additionalServicesSumTotal"
                        value={sumTotal}
                        handleChange={handleChange}
                        label="Ukupna cena dodataka"
                    />
                    <Button onClick={handleSave} text="Sačuvaj" />
                </div>
            </div>
        </div>
    );
};

interface IReservationsAdditionalServicesModal {
    additionalServices: (IAgencyAdditionalService & { checked: boolean })[];
    setState: React.Dispatch<SetStateAction<IReservationsState>>;
    closeModal: () => any;
    additionalServicesSumTotal: number;
    setInvalidFields: React.Dispatch<SetStateAction<string[]>>;
}
