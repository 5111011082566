import { ISelectValue } from "../../components/Select"
import { IAgencyAdditionalService, IAgencySeason } from "../../types/agencies.interface"
import { IVehicleAgencySeasonRentPeriodPrice } from "../../types/vehicles.interface"

export const initialState:IReservationsState = {
    firstName:'',
    lastName:'',
    phoneNumber:'',
    license:'',
    email:'',
    fromDate:null,
    toDate:null,
    vehicleReturnMethod:{value:'',label:''},
    vehiclePickupMethod:{value:'',label:''},
    vehiclePickupCity:{value:'',label:''},
    vehiclePickupAddress:'',
    vehicleReturnCity:{value:'',label:''},
    vehicleReturnAddress:'',
    note:'',
    agency:{value:'',label:'',additionalServices:[],seasons:[]},
    vehicle:{value:'',label:'', vehicleAgencySeasonRentPeriodPrices:[]},
    additionalServicesSumTotal:0,
    additionalServices:[],
    price:0
    
}

export interface IReservationsState{
    clientId?:number
    firstName:string
    lastName:string
    phoneNumber:string
    license:string
    email:string
    fromDate:Date | null
    toDate:Date | null
    vehicleReturnMethod:ISelectValue
    vehiclePickupMethod:ISelectValue
    vehiclePickupCity:ISelectValue
    vehiclePickupAddress:string
    vehicleReturnCity:ISelectValue
    vehicleReturnAddress:string
    note:string
    agency:{value:string,seasons:IAgencySeason[], label:string,additionalServices:(IAgencyAdditionalService & { checked: boolean })[]},
    vehicle:(ISelectValue & {vehicleAgencySeasonRentPeriodPrices:IVehicleAgencySeasonRentPeriodPrice[]} )
    additionalServicesSumTotal:number
    price:number
    additionalServices?:IAgencyAdditionalService[]
}

export const statuses = [{
    label:'Nova rezervacija',
    value:1,
    color:'#fff'
},
{
    label:'U obradi',
    value:2,
    color:'#ffbb00'
},
{
    label:'Prihvaćeno',
    value:3,
    color:'#13B47A'
},
{
    label:'Otkazano',
    value:4,
    color:'#F33636'
},
]


export const invalidReservationsFields = Object.keys(initialState).filter(f=>!['license','price', 'vehiclePickupCity','vehiclePickupAddress','vehicleReturnCity','vehicleReturnAddress','additionalServicesSumTotal', 'additionalServices'].includes(f))