
import { Navigate } from 'react-router-dom';

export const ProtectedRoute = ({ children }: any) => {
    const token = localStorage.getItem('access_token');

    if (!token) {
        return <Navigate to='auth' />;
    } else
        return children;
};
