import { useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader"
import { RootState } from "../store";

export const Loader = () => {

    const isLoadingUser = useSelector((state: RootState) => state.user.isLoading)
    const isLoadingVehicles = useSelector((state: RootState) => state.vehicles.isLoading)
    const isLoadingRequests = useSelector((state: RootState) => state.requests.isLoading)
    const isLoadingAgencies = useSelector((state: RootState) => state.agencies.isLoading)
    const isLoadingReservations = useSelector((state: RootState) => state.reservations.isLoading)
    const isLoadingAddotionalServices = useSelector((state: RootState) => state.additionalServices.isLoading)
    const isLoadingClients = useSelector((state: RootState) => state.clients.isLoading)
    const isLoadingSideData = useSelector((state: RootState) => state.sideData.isLoading)
    const isLoadingRentPeriods = useSelector((state: RootState) => state.rentPeriods.isLoading)


    return <ClipLoader
        color={'#ff9900'}
        loading={isLoadingAddotionalServices || isLoadingAgencies || isLoadingClients || isLoadingRequests || isLoadingReservations || isLoadingSideData || isLoadingVehicles || isLoadingRentPeriods || isLoadingUser}
        size={150}
        aria-label="Loading Spinner"
        className="spinner"
        data-testid="loader"
    />
}