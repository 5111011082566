import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch from "../../utils/axios";
import { toast } from "react-toastify";
import { handleErrors } from "../../utils/helpers";

const initialState = {
  isLoading: false,
  logged: false,
  user: null,
};

export const loginUser = createAsyncThunk(
  "user/login",
  async (user: any, thunkApi) => {
    try {
      const resp = await customFetch.post("auth/login", user);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const refreshToken = createAsyncThunk(
  `user/refreshToken`,
  async (_, { getState }) => {

    const res = await customFetch.post(`auth/login/refresh-token`);

    return res.data;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logOutUser: (state: any) => {
      state.logged = true;
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
      window.location.reload()
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, { payload }: any) => {
        state.isLoading = false;
        localStorage.setItem("access_token", payload.access_token);
        localStorage.setItem("refresh_token", payload.refresh_token);
        state.logged = true;
      })
      .addCase(loginUser.rejected, (state, {payload}: any) => {
        state.isLoading = false;
        handleErrors(payload)
      })
      .addCase(refreshToken.fulfilled,(state,{payload})=>{
        localStorage.setItem('access_token',payload.access_token)
        localStorage.setItem('refresh_token',payload.refresh_token)
      })
      
  },
});
export const { logOutUser } = userSlice.actions

export default userSlice.reducer;
