import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch from "../../utils/axios";
import { IGetSingleRentPeriod } from "../../types/rent-periods.interface";
import { ILocalizedTextId, INameId } from "../../types/global.interface";
import { handleErrors } from "../../utils/helpers";

const initialState: IInitialState = {
  isLoading: false,
  rentPeriods: [],
  mileage: [],
  seasons: [],
};

interface IInitialState {
  isLoading: boolean;
  rentPeriods: IGetSingleRentPeriod[];
  mileage: ILocalizedTextId[];
  seasons: INameId[];
}

export const getRentPeriods = createAsyncThunk(
  "sideData/getRentPeriods",
  async (_, thunkApi) => {
    try {
      const resp = await customFetch.get("rent-period");
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getMileage = createAsyncThunk(
  "sideData/getMileage",
  async (_, thunkApi) => {
    try {
      const resp = await customFetch.get("mileage");
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getSeasons = createAsyncThunk(
  "sideData/getSeasons",
  async (_, thunkApi) => {
    try {
      const resp = await customFetch.get("seasons");
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const sideDataSlice = createSlice({
  name: "sideData",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getRentPeriods.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRentPeriods.fulfilled, (state, { payload }: any) => {
        state.isLoading = false;
        state.rentPeriods = payload;
      })
      .addCase(getRentPeriods.rejected, (state, { payload }: any) => {
        state.isLoading = false;
        handleErrors(payload);
      })
      .addCase(getMileage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMileage.fulfilled, (state, { payload }: any) => {
        state.isLoading = false;
        state.mileage = payload;
      })
      .addCase(getMileage.rejected, (state, { payload }: any) => {
        state.isLoading = false;
        handleErrors(payload);
      })
      .addCase(getSeasons.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSeasons.fulfilled, (state, { payload }: any) => {
        state.isLoading = false;
        state.seasons = payload;
      })
      .addCase(getSeasons.rejected, (state, { payload }: any) => {
        state.isLoading = false;
        handleErrors(payload);
      });
  },
});

export default sideDataSlice.reducer;
