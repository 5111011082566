import { toast } from "react-toastify";
import { ISelectValue } from "../components/Select";
import { IAgencyRentPeriodMileage } from "../types/agencies.interface";
import { IRentPeriodMileage } from "../views/Agencies/MileageModal";
import { IVehicleSeasonRentPeriod, IVehicleStateSeason } from "../views/Vehicles/data";

export function formatDate(dt:string) {
    // Get the components of the date
    let date = new Date(dt)
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    // Construct the formatted date string
    const formattedDate = `${month}/${day}/${year}`;
  
    return formattedDate;
  }

  export const getAmPm = (dt:string) =>{
      const date = new Date(dt)
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    // Determine whether it's AM or PM
    const amOrPm = Number(hours) >= 12 ? 'PM' : 'AM';
  
    // Convert hours to 12-hour format
    const formattedHours = Number(hours) % 12 || 12;
    return  `${formattedHours}:${minutes} ${amOrPm}`
  }


  export const changeState = (setState:React.Dispatch<React.SetStateAction<any>>,propName:string,value:any) =>{
    setState((prev:any)=>{
      const copy = structuredClone(prev)
      copy[propName] = value
      return copy
    })
  }

  export function daysBetweenDates(startDate:Date, endDate:Date) {

    if(!startDate || !endDate){
      return 0
    }
    // Convert both dates to milliseconds
    var startMs = startDate.getTime();
    var endMs = endDate.getTime();

    // Calculate the difference in milliseconds
    var diffMs = Math.abs(endMs - startMs);

    // Convert milliseconds to days
    var days = Math.floor(diffMs / (1000 * 60 * 60 * 24));

    // Check if there is any time remaining after subtracting whole days
    var remainingMs = diffMs % (1000 * 60 * 60 * 24);
    if (remainingMs > 0) {
        // If there is at least one second remaining, count it as another day
        days++;
    }

    return days;
}

export const handleErrors = (payload) => {

  console.log(payload)


  if(payload.message.includes('401')){
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    toast.info('Vreme trajanja prijave je isteko, prijavite se ponovo.')
    window.location.reload()
    return
  }

  if ( payload.response.data?.data?.message && Array.isArray(payload.response.data.data.message)) {
    return payload.response.data.data.message.map((i) => toast.error(i))
  }else{
    toast.error(payload.response?.data?.message)

  }

}

export function sortRentPeriodMileages(arr:IRentPeriodMileage[]) {
  const sorted = arr.sort((a, b) => {
    // Extracting the numbers from the label
    const [aFrom, aTo] = (a.rentPeriod.label as string ).split('-').map(Number);
    const [bFrom, bTo] = (b.rentPeriod.label as string ).split('-').map(Number);
    
    // Comparing based on the "from" values
    if (aFrom !== bFrom) {
      return aFrom - bFrom;
    }
    
    // If "from" values are equal, compare based on the "to" values
    return aTo - bTo;
  });

  return sorted.map((f,index)=>({...f,index}))
}


export function sortRentPeriodSeasons(arr:IVehicleSeasonRentPeriod[]) {

  const sorted = arr.sort((a, b) => {
    // Extracting the numbers from the label

    // Comparing based on the "from" values
    if (a.from !== b.from) {
      return a.from - b.from;
    }
    
    // If "from" values are equal, compare based on the "to" values
    return a.to - b.to;
  });

  return sorted.filter(a=>a.price).map((f,index)=>({...f,index}))
}


export const formatStateRPM = (arr:IRentPeriodMileage[]) =>{
  return Array.from({ length: 9 }, (_, index) => {

    const initialObject = { rentPeriod: null, mileage: null, index }
    const foundRPM = arr.find(rpm=>rpm.index === index)
    if(foundRPM){
      return foundRPM
    }else{
      return initialObject
    }
  })
}

export const formatStateSeasonRP = (arr:IVehicleSeasonRentPeriod[],rps?:IAgencyRentPeriodMileage[]):IFormatedSeasonRentPeriod[] =>{
  return Array.from({ length: 9 }, (_, index) => {
    const initialObject = { rentPeriod: null, price: null, index, id:null }
    if(arr.length < 1){
      const rentPeriods = sortByRentPeriod(rps)
      const foundRPM:IAgencyRentPeriodMileage = rentPeriods[index]
      if(foundRPM){
      return { rentPeriod: {label:foundRPM.rentPeriod.from+'-'+foundRPM.rentPeriod.to, value:foundRPM.rentPeriodId}, price: 0, index,id:foundRPM.rentPeriodId }
      }else{
        return initialObject
      }
    }else{
      const foundRPM:IVehicleSeasonRentPeriod= arr.find(rpm=>rpm.index === index)
      if(foundRPM){
        return { rentPeriod: {label:foundRPM.from+'-'+foundRPM.to, value:foundRPM.id}, price: foundRPM.price, index,prevId:foundRPM.prevId,id:foundRPM.id }
      }else{
        return initialObject
      }
    }
  })
}

function sortByRentPeriod(objs) {
  const objects = structuredClone(objs)
  // Custom sorting function
  function compareRentPeriod(a, b) {
      // Compare 'from' values
      if (a.rentPeriod.from < b.rentPeriod.from) {
          return -1;
      } else if (a.rentPeriod.from > b.rentPeriod.from) {
          return 1;
      } else {
          // If 'from' values are equal, compare 'to' values
          if (a.rentPeriod.to < b.rentPeriod.to) {
              return -1;
          } else if (a.rentPeriod.to > b.rentPeriod.to) {
              return 1;
          } else {
              return 0;
          }
      }
  }

  // Sort the array using the custom sorting function
  try {
  objects.sort(compareRentPeriod);
    
  } catch (error) {
    return []
  }

  return objects;
}

interface IFormatedSeasonRentPeriod{
  rentPeriod:ISelectValue
  price:number
  index:number
  prevId?:number
  id:number
}