import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { Button } from "../../components/Button"
import { Input } from "../../components/Input"
import { addClient } from "../../features/clients/clientsSlice"
import { AppDispatch } from "../../store"
import { changeState } from "../../utils/helpers"
import { standardFieldValidation } from "../../utils/validationUtils"
import { clientsInitialState, invalidClientsFields } from "./data"

export const AddClient = () => {

    const [state, setState] = useState(clientsInitialState)
    const [invalidFields, setInvalidFields] = useState(invalidClientsFields)
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        standardFieldValidation(e, setInvalidFields)
        changeState(setState, name, value)
    }

    const handleSubmit = async () => {
        if (invalidFields.length > 0) {
            toast.warn('Sva polja moraju biti popunjena.')
            return
        }
        const resp = await dispatch(addClient(state))
        if (resp.meta.requestStatus === 'fulfilled') {
            navigate('/clients')
        }
    }

    return <div className="form-page" >
        <div className="form-grid" >
            <Input value={state.firstName} name='firstName' label='Ime' handleChange={handleChange} />
            <Input value={state.lastName} name='lastName' label='Prezime' handleChange={handleChange} />
            <Input value={state.phoneNumber} name='phoneNumber' label='Broj telefona' handleChange={handleChange} />
            <Input value={state.email} name='email' label='Email' handleChange={handleChange} />
            <Input value={state.licenseNumber} name='licenseNumber' label='Broj vozačke' handleChange={handleChange} />
        </div>
        <div className="page-bottom" >
            <Button text="Sačuvaj" onClick={handleSubmit} />
        </div>
    </div>
}