import Check from '../assets/icons/Check.svg'

export const TextDisplayButton = ({ text, label, width, checked, onClick }: ITextDisplayButton) => {

    return <div className="input-container" style={width ? { width } : {}} onClick={onClick} >
        {label && <p className='input-container__label' >{label}</p>}
        <div className="input-container__input"  >
            <p className="input-container__input--value" style={{ margin: 'unset', fontSize: '14px', fontWeight: 400 }} >{text}</p>
            <div className='input-container__input--check' style={{ backgroundColor: checked ? '#ffbb00' : 'white' }} > {checked && <img src={Check} alt='' />} </div>
        </div>
    </div>
}

interface ITextDisplayButton {
    text?: string
    label?: string
    width?: string
    checked: boolean
    onClick: any
}