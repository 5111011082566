import { Button } from "../../components/Button"
import { FilterSelect } from "../../components/FilterSelect"
import { INameId } from "../../types/global.interface"
import { IVehicleDropDownItems } from "../../types/vehicles.interface"
import { IVehicleFilters, vehicleFilters } from "./data"
import Suv from '../../assets/icons/SUV.svg'
import Sedan from '../../assets/icons/Sedan.svg'
import Hatchback from '../../assets/icons/Hatchback.svg'
import Van from '../../assets/icons/Van.svg'
import Wagon from '../../assets/icons/Wagon.svg'
import Coupe from '../../assets/icons/Coupe.svg'
import Check from '../../assets/icons/Check.svg'
import React, { SetStateAction, useState } from "react"
import { changeState } from "../../utils/helpers"
import { ISelectValue } from "../../components/Select"
import { IGetSingleAgency } from "../../types/agencies.interface"

export const VehiclesFilter = ({ state, setState }: IVehiclesFilter) => {

    const [localState, setLocalState] = useState(state)
    const handleSelect = (value: ISelectValue, name: string) => {
        changeState(setLocalState, name, value)
    }

    // const handleVehicleType = (id: number) => {
    //     let newTypes = structuredClone(localState.vehicleTypes)
    //     if (newTypes.includes(id)) {
    //         newTypes = newTypes.filter(t => t !== id)
    //     } else {
    //         newTypes.push(id)
    //     }
    //     changeState(setLocalState, 'vehicleTypes', newTypes)
    // }

    // const getClassName = (id: number) => {
    //     let cName = 'vehicles-filter__types--single-type'
    //     if (localState.vehicleTypes.includes(id)) {
    //         cName += ' selected'
    //     }
    //     return cName
    // }

    const handleApplyFilters = () => {
        setState(localState)
    }

    const hasValues = () => {
        // if (localState.vehicleTypes.length > 0) {
        //     return true
        // }
        const keys = Object.keys(localState)
        for (const k of keys) {
            if (localState[k]?.value) {
                return true
            }
        }
    }

    const handleResetFilters = () => {
        setLocalState(vehicleFilters)
        setState(vehicleFilters)
    }

    return <div className="vehicles-filter" >
        <div className="vehicles-filter__selects" >
            <FilterSelect zIndex={6} link="vehicle-dropdown-items" label="Marka" name="brand" value={localState.brand} handleChange={handleSelect} parserFunction={(vdi: IVehicleDropDownItems) => vdi.brands.map(b => ({ label: b.name, value: b.id }))} />
            <FilterSelect zIndex={5} link="agencies" label="Agencija" name="agency" value={localState.agency} handleChange={handleSelect} parserFunction={(a: IGetSingleAgency[]) => a.map(a => ({ label: a.name, value: a.id }))} />
            <FilterSelect zIndex={4} link="model" label="Model" name="model" value={localState.model} handleChange={handleSelect} parserFunction={(models: INameId[]) => models.map(m => ({ label: m.name, value: m.id }))} />
            <FilterSelect zIndex={3} link="vehicle-dropdown-items" label="Menjač" name="transmissionType" value={localState.transmissionType} handleChange={handleSelect} parserFunction={(vdi: IVehicleDropDownItems) => vdi.transmissionTypes.map(t => ({ label: t.localizedText, value: t.id }))} />
            <FilterSelect zIndex={2} link="vehicle-dropdown-items" label="Tip" name="vehicleType" value={localState.vehicleType} handleChange={handleSelect} parserFunction={(vdi: IVehicleDropDownItems) => vdi.vehicleTypes.map(c => ({ label: c.localizedText, value: c.id }))} />
            <FilterSelect zIndex={1} link="vehicle-dropdown-items" label="Gorivo" name="fuelType" value={localState.fuelType} handleChange={handleSelect} parserFunction={(vdi: IVehicleDropDownItems) => vdi.fuelTypes.map(f => ({ label: f.localizedText, value: f.id }))} />
        </div>

        {/* <div className="vehicles-filter__types" >
            <div className={getClassName(2)} onClick={() => handleVehicleType(2)} style={{ zIndex: 1 }} >
                {getClassName(2).includes('selected') &&
                    <div className="selected-box" >
                        <img src={Check} alt='' />
                    </div>}
                <img src={Hatchback} alt='' />
                <p>Hatchback</p>
            </div>
            <div className={getClassName(7)} onClick={() => handleVehicleType(7)} >
                {getClassName(7).includes('selected') &&
                    <div className="selected-box" >
                        <img src={Check} alt='' />
                    </div>}
                <img src={Sedan} alt='' />
                <p>Sedan</p>
            </div>
            <div className={getClassName(1)} onClick={() => handleVehicleType(1)} >
                {getClassName(1).includes('selected') &&
                    <div className="selected-box" >
                        <img src={Check} alt='' />
                    </div>}
                <img src={Suv} alt='' />
                <p>SUV</p>
            </div>
            <div className={getClassName(6)} onClick={() => handleVehicleType(6)}>
                {getClassName(6).includes('selected') &&
                    <div className="selected-box" >
                        <img src={Check} alt='' />
                    </div>}
                <img src={Coupe} alt='' />
                <p>Coupe</p>
            </div>
            <div className={getClassName(4)} onClick={() => handleVehicleType(4)}>
                {getClassName(4).includes('selected') &&
                    <div className="selected-box" >
                        <img src={Check} alt='' />
                    </div>}
                <img src={Wagon} alt='' />
                <p>Wagon</p>
            </div>
            <div className={getClassName(8)} onClick={() => handleVehicleType(8)} >
                {getClassName(8).includes('selected') &&
                    <div className="selected-box" >
                        <img src={Check} alt='' />
                    </div>}
                <img src={Van} alt='' />
                <p>Van</p>
            </div>
        </div> */}
        <div className="vehicles-filter__buttons" >
            {hasValues() && <Button cancel text="Resetuj" onClick={handleResetFilters} />}
            <Button text="Primeni" onClick={handleApplyFilters} />
        </div>
    </div>
}

interface IVehiclesFilter {
    state: IVehicleFilters
    setState: React.Dispatch<SetStateAction<IVehicleFilters>>
}