import calendar from '../assets/icons/Calendar.svg'
import { useState } from "react";
import Select, { ISelectValue } from "../components/Select";
import { months } from "../utils/data";
import { useClickAway } from "@uidotdev/usehooks";
import { toast } from "react-toastify";

export default function DatePicker({
    label,
    value,
    handleChange,
    minDate,
    width,
    name
}: IDateTimePicker) {
    const ref: any = useClickAway(() => {
        setOpenPicker(false);
    });
    const [openPicker, setOpenPicker] = useState(false);
    const [localDate, setLocalDate] = useState<any>({
        day: new Date().getDate(),
        month: months.find((m) => m.value == new Date().getMonth()),
        year: { label: new Date().getFullYear(), value: new Date().getFullYear() },
        hour: null,
        min: null,
    });

    const getMonthDays = () => {
        if (localDate.month.value !== 1) {
            return months[localDate.month.value].days;
        }

        function leapyear(year: number) {
            // Return true if the year is divisible by 4 but not divisible by 100 unless it's also divisible by 400
            return year % 100 === 0 ? year % 400 === 0 : year % 4 === 0;
        }

        return leapyear(localDate.year.value) ? 29 : 28;
    };

    const handleSelect = (v: ISelectValue | number, name: string) => {
        setLocalDate((prev: any) => {
            const copy = structuredClone(prev);
            if (name === "month") {
                copy.day = 1;
            }
            copy[name] = v;
            return copy;
        });
    };

    const addZero = (n: number) => {
        let s = String(n);
        if (s.length < 2) {
            s = "0" + s;
        }
        return s;
    };

    const handleConfirm = () => {
        if (!localDate.min || !localDate.hour) {
            toast.error("Time must be set");
            return;
        }
        handleChange(
            new Date(
                localDate.year.value,
                localDate.month.value,
                localDate.day,
                localDate.hour.value,
                localDate.min.value
            ), name
        );
        setOpenPicker(false);
    };

    const formatDate = () => {
        if (value) {
            const day = value.getDate();
            const month = months[value.getMonth()].label;
            const year = value.getFullYear();
            const hours = addZero(value.getHours());
            const mins = addZero(value.getMinutes());
            return day + ". " + month + " " + year + ". " + hours + ":" + mins;
        }
    };

    const position = (ref.current as any)?.getBoundingClientRect();

    const isDiabledDay = (d: number) => {
        const newDate = new Date(
            localDate.year.value,
            localDate.month.value,
            localDate.day
        );
        newDate.setDate(d);

        if (minDate && newDate.getTime() <= minDate.getTime()) {
            return " past-day";
        }

        if (
            newDate.getTime() <
            new Date(
                localDate.year.value,
                localDate.month.value,
                localDate.day
            ).getTime()
        ) {
        }

        return "";
    };

    return (
        <div className="input-container" style={width ? { width } : {}} ref={ref}>
            <p className='input-container__label' >{label}</p>
            <div
                className="input-container__input"
                onClick={() => setOpenPicker(true)}
            >
                {!value ? (
                    <p className="placeholder"> Odaberite datum </p>
                ) : (
                    <p className='input-container__input--value' >{formatDate()}</p>
                )}
                <img src={calendar} alt="" />
            </div>
            {openPicker && (
                <div
                    className="datetime-picker"
                >
                    <div className="date-picker">
                        <div className="calendar-title">
                            <Select
                                handleChange={handleSelect}
                                label="Mesec"
                                options={months}
                                value={localDate.month}
                                name='month'
                            />
                            <Select
                                handleChange={handleSelect}
                                label={"Godina"}
                                options={Array.from(
                                    { length: 2030 - new Date().getFullYear() + 1 },
                                    (_, index) => new Date().getFullYear() + index
                                ).map((y) => ({ label: y, value: y }))}
                                value={localDate.year}
                                name='year'
                            />
                        </div>
                        <div className="calendar-days">
                            {Array.from(
                                { length: getMonthDays() },
                                (_, index) => index + 1
                            ).map((d) => (
                                <p
                                    onClick={() => handleSelect(d, "day")}
                                    key={d}
                                    className={
                                        "single-day " +
                                        (parseInt(localDate.day) == d && !isDiabledDay(d)
                                            ? " selected-day"
                                            : "" + isDiabledDay(d))
                                    }
                                >
                                    {d}
                                </p>
                            ))}
                        </div>
                    </div>
                    <div className="time-picker">
                        <p className="form-subheadline">Vreme</p>
                        <div className="time-picker__select">
                            <Select
                                placeholder="hh"
                                value={localDate.hour}
                                options={Array.from({ length: 24 }, (_, index) => index).map(
                                    (h) => ({ label: addZero(h), value: String(h) })
                                )}
                                handleChange={handleSelect}
                                name='hour'
                            />
                            <Select
                                placeholder="mm"
                                options={Array.from({ length: 60 }, (_, index) => index).map(
                                    (m) => ({ label: addZero(m), value: String(m) })
                                )}
                                value={localDate.min}
                                handleChange={handleSelect}
                                name='min'
                            />
                        </div>
                        <div className="calendar-confirm" onClick={handleConfirm}>
                            {"Gotovo"}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

interface IDateTimePicker {
    label: string;
    value?: Date;
    handleChange: (d: Date, name: string) => any;
    minDate?: Date;
    width?: string,
    name: string
}
