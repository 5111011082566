export const AddressBookIcon = ({ fill }: { fill?: string }) => {
    return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.625 6.875C9.58947 6.875 8.75 7.71447 8.75 8.75C8.75 9.78553 9.58947 10.625 10.625 10.625C11.6605 10.625 12.5 9.78553 12.5 8.75C12.5 7.71447 11.6605 6.875 10.625 6.875ZM7.5 8.75C7.5 7.02411 8.89911 5.625 10.625 5.625C12.3509 5.625 13.75 7.02411 13.75 8.75C13.75 10.4759 12.3509 11.875 10.625 11.875C8.89911 11.875 7.5 10.4759 7.5 8.75Z" fill={fill || "#969BA0"} />
        <path fillRule="evenodd" clipRule="evenodd" d="M1.875 8.4375C1.875 8.09232 2.15482 7.8125 2.5 7.8125H4.375C4.72018 7.8125 5 8.09232 5 8.4375C5 8.78268 4.72018 9.0625 4.375 9.0625H2.5C2.15482 9.0625 1.875 8.78268 1.875 8.4375Z" fill={fill || "#969BA0"} />
        <path fillRule="evenodd" clipRule="evenodd" d="M1.875 5.3125C1.875 4.96732 2.15482 4.6875 2.5 4.6875H4.375C4.72018 4.6875 5 4.96732 5 5.3125C5 5.65768 4.72018 5.9375 4.375 5.9375H2.5C2.15482 5.9375 1.875 5.65768 1.875 5.3125Z" fill={fill || "#969BA0"} />
        <path fillRule="evenodd" clipRule="evenodd" d="M1.875 11.5625C1.875 11.2173 2.15482 10.9375 2.5 10.9375H4.375C4.72018 10.9375 5 11.2173 5 11.5625C5 11.9077 4.72018 12.1875 4.375 12.1875H2.5C2.15482 12.1875 1.875 11.9077 1.875 11.5625Z" fill={fill || "#969BA0"} />
        <path fillRule="evenodd" clipRule="evenodd" d="M1.875 14.6875C1.875 14.3423 2.15482 14.0625 2.5 14.0625H4.375C4.72018 14.0625 5 14.3423 5 14.6875C5 15.0327 4.72018 15.3125 4.375 15.3125H2.5C2.15482 15.3125 1.875 15.0327 1.875 14.6875Z" fill={fill || "#969BA0"} />
        <path fillRule="evenodd" clipRule="evenodd" d="M10.625 11.875C9.99435 11.875 9.37233 12.0218 8.80823 12.3039C8.24413 12.5859 7.75345 12.9955 7.37504 13.5C7.16793 13.7761 6.77618 13.8321 6.50004 13.625C6.22389 13.4179 6.16793 13.0261 6.37504 12.75C6.86988 12.0902 7.51154 11.5547 8.24921 11.1859C8.98688 10.817 9.8003 10.625 10.625 10.625C11.4498 10.625 12.2632 10.817 13.0009 11.1859C13.7385 11.5547 14.3802 12.0902 14.875 12.75C15.0821 13.0261 15.0262 13.4179 14.75 13.625C14.4739 13.8321 14.0821 13.7761 13.875 13.5C13.4966 12.9955 13.0059 12.5859 12.4418 12.3039C11.8777 12.0218 11.2557 11.875 10.625 11.875Z" fill={fill || "#969BA0"} />
        <path fillRule="evenodd" clipRule="evenodd" d="M16.25 1.875C16.9404 1.875 17.5 2.43464 17.5 3.125V16.875C17.5 17.5654 16.9404 18.125 16.25 18.125H5C4.30964 18.125 3.75 17.5654 3.75 16.875L3.75 3.125C3.75 2.43464 4.30964 1.875 5 1.875L16.25 1.875ZM16.25 16.875V3.125L5 3.125L5 16.875H16.25Z" fill={fill || "#969BA0"} />
    </svg>

}