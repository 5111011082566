import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch from "../../utils/axios";
import { toast } from "react-toastify";
import { IGetSingleReservation } from "../../types/reservations.interface";
import { IReservationsState } from "../../views/Reservations/data";
import { handleErrors } from "../../utils/helpers";
import { cities, pickupReturnMethods } from "../../utils/data";

const initialState:IState = {
  isLoading: false,
  reservations: [],
  singleReservation:null
};

interface IState{
  isLoading:boolean
  reservations:IGetSingleReservation[]
  singleReservation:IReservationsState
}

export const getReservations = createAsyncThunk(
  "reservations/getAll",
  async (_, thunkApi) => {
    try {
      const resp = await customFetch.get("reservations");
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const cancelReservation = createAsyncThunk(
  "reservations/cancel",
  async (id:string, thunkApi) => {
    try {
      const resp = await customFetch.patch("reservations/"+id+'/cancel');
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteReservation = createAsyncThunk(
  "reservations/delete",
  async (id:number, thunkApi) => {
    try {
     await customFetch.delete("reservations/"+id);
      return id
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getSingleReservation = createAsyncThunk(
  "reservations/getSingle",
  async (id:string, thunkApi) => {
    try {
      const resp = await customFetch.get("reservations/"+id);
      const reservationData:IGetSingleReservation = resp.data
    
      const  filterUniqueById =(array) => {
        const uniqueMap = new Map();
        array.forEach(obj => {
            uniqueMap.set(obj.id, obj);
        });
        return Array.from(uniqueMap.values());
    }

      const formattedForState:IReservationsState ={
        additionalServicesSumTotal:reservationData.additionalServicesSumTotal,
        additionalServices:reservationData.additionalServices.map(as=>({...as,checked:true})),
        clientId:reservationData.client.id,
        firstName:reservationData.client.firstName,
        lastName:reservationData.client.lastName,
        phoneNumber:reservationData.client.phoneNumber,
        email:reservationData.client.email,
        license:reservationData.client.licenseNumber,
        fromDate:new Date(reservationData.fromDate),
        toDate:new Date(reservationData.toDate),
        vehiclePickupMethod: pickupReturnMethods.find(m=>m.value === reservationData.vehiclePickupMethodType ),
        vehiclePickupAddress: reservationData.vehiclePickupAddress,
        vehiclePickupCity: cities.find(c=>c.label === reservationData.vehiclePickupCity), 
        vehicleReturnMethod: pickupReturnMethods.find(m=>m.value === reservationData.vehicleReturnMethodType),
        vehicleReturnCity: cities.find(c=>c.label === reservationData.vehicleReturnCity), 
        vehicleReturnAddress: reservationData.vehicleReturnAddress,
        note:reservationData.note,
        agency:{
          value:String(reservationData.vehicle.agency.id),
          label:reservationData.vehicle.agency.name,
        seasons:filterUniqueById(reservationData.vehicle.vehicleAgencySeasonRentPeriodPrices.filter(r=>r.agencySeason).map(r=>r.agencySeason)),
          additionalServices:reservationData.vehicle.agency.agencyAdditionalServices.map(as=>({id:as.id,agencyId:reservationData.vehicle.agency.id,additionalService:{id:as.id,name:as.additionalService.name}, price:as.price,name:'',checked:reservationData.additionalServices.some(ads=>ads.additionalService.id == as.additionalService.id)})),
        },
        vehicle:{
          label:reservationData.vehicle.brand.name + reservationData.vehicle.model.name,
          value:reservationData.vehicle.id,
          vehicleAgencySeasonRentPeriodPrices:reservationData.vehicle.vehicleAgencySeasonRentPeriodPrices
        },
        price:reservationData.price,
      }

      return formattedForState;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveReservation = createAsyncThunk(
  "reservations/save",
  async ({reservation,shouldAccept}:{reservation: IReservationsState,shouldAccept:boolean}, thunkApi) => {

    const obj = {
      fromDate:reservation.fromDate,
      toDate:reservation.toDate,
      vehiclePickupMethodType:reservation.vehiclePickupMethod.value,
      vehiclePickupCity:reservation.vehiclePickupCity.label || '',
      vehiclePickupAddress:reservation.vehiclePickupAddress,
      vehicleReturnMethodType:reservation.vehicleReturnMethod.value,
      vehicleReturnCity:reservation.vehicleReturnCity.label || '',
      vehicleReturnAddress:reservation.vehicleReturnAddress,
      vehicleId:reservation.vehicle.value,
      shouldAccept,
      note:reservation.note,
      price:reservation.price,
      additionalServiceIds:reservation.additionalServices.filter(s=>s.checked).map(s=>s.id),
      additionalServicesSumTotal:reservation.additionalServicesSumTotal
    }

    const clientObj = {
      firstName:reservation.firstName,
      lastName:reservation.lastName,
      email:reservation.email,
      phoneNumber:reservation.phoneNumber,
      licenseNumber:reservation.license
    }

    let client
    try {
        client = await customFetch.post('client', clientObj)
    } catch (error) {
        if((error as any ).response.status === 409){
          client = {data:{id:(error as any).response.data.data.clientId}}
        }else{
          return thunkApi.rejectWithValue(error);
        }
    }

    try {
     
      const resp = await customFetch.post("reservations",{...obj,clientId:client.data.id});
      return resp.data;
    } catch (error) {
      console.log(error)
      return thunkApi.rejectWithValue(error);
    }
  }
);


export const editReservation = createAsyncThunk(
  "reservations/edit",
  async ({reservation,status,id}:{reservation: IReservationsState,status:number, id?:string}, thunkApi) => {

    const obj = {
      fromDate:reservation.fromDate,
      toDate:reservation.toDate,
      vehiclePickupMethodType:reservation.vehiclePickupMethod.value,
      vehiclePickupCity:reservation.vehiclePickupCity?.label || '',
      vehiclePickupAddress:reservation.vehiclePickupAddress,
      vehicleReturnMethodType:reservation.vehicleReturnMethod.value,
      vehicleReturnCity:reservation.vehicleReturnCity?.label || '',
      vehicleReturnAddress:reservation.vehicleReturnAddress,
      vehicleId:reservation.vehicle.value,
      additionalServiceIds:reservation.additionalServices.filter(s=>s.checked).map(s=>s.id),
      additionalServicesSumTotal:reservation.additionalServicesSumTotal,
      note:reservation.note,
      price:reservation.price,
      status
    }

    const clientObj = {
      firstName:reservation.firstName,
      lastName:reservation.lastName,
      email:reservation.email,
      phoneNumber:reservation.phoneNumber,
      licenseNumber:reservation.license
    }

    try {
      const client = await customFetch.patch('client/'+reservation.clientId, clientObj)
      const resp = await customFetch.patch("reservations/"+id,{...obj,clientId:client.data.id});
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const reservationsSlice = createSlice({
  name: "reservations",
  initialState,
  reducers: {
    clearReservations: (state: any) => {
      state.reservations = []
      state.singleReservation = null
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getReservations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getReservations.fulfilled, (state, { payload }: any) => {
        state.isLoading = false;
        state.reservations = payload
      })
      .addCase(getReservations.rejected, (state, {payload}: any) => {
        state.isLoading = false;
        toast.success('Rezervacija uspešno otkazana.')
        handleErrors(payload)
      })
      .addCase(cancelReservation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(cancelReservation.fulfilled, (state, { payload }: any) => {
        state.isLoading = false;
        state.reservations = payload
      })
      .addCase(cancelReservation.rejected, (state, {payload}: any) => {
        state.isLoading = false;
        handleErrors(payload)
      })
      .addCase(editReservation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editReservation.fulfilled, (state) => {
        toast.success('Rezervacija uspešno izmenjena.')
        state.isLoading = false;
      })
      .addCase(editReservation.rejected, (state, {payload}: any) => {
        state.isLoading = false;
        handleErrors(payload)
      })
      .addCase(deleteReservation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteReservation.fulfilled, (state, { payload }: any) => {
        state.isLoading = false;
        state.reservations = state.reservations.filter(r=>r.id!==payload)
        toast.success('Rezervacija uspešno obrisana.')
      })
      .addCase(deleteReservation.rejected, (state, {payload}: any) => {
        state.isLoading = false;
        handleErrors(payload)
      })
      .addCase(getSingleReservation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSingleReservation.fulfilled, (state,  {payload}:any) => {
        state.isLoading = false;
        state.singleReservation = payload
      })
      .addCase(getSingleReservation.rejected, (state, {payload}: any) => {
        state.isLoading = false;
        handleErrors(payload)
      })
      .addCase(saveReservation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveReservation.fulfilled, (state, { payload }: any) => {
        state.isLoading = false;
        state.reservations.push(payload)
        toast.success('Rezervacija uspešno sačuvana.')
      })
      .addCase(saveReservation.rejected, (state, {payload}: any) => {
        state.isLoading = false;
        handleErrors(payload)
      })
  },
});

export const { clearReservations } = reservationsSlice.actions

export default reservationsSlice.reducer;
