import { useState } from "react"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { Button } from "../../components/Button"
import { Input } from "../../components/Input"
import { addAdditionalService, editAdditionalService } from "../../features/additional-services/additionalServicesSlice"
import { AppDispatch } from "../../store"

export const FormModal = ({ type, id, value, setModal }: IFormModal) => {


    const [name, setName] = useState(value ?? '')
    const dispatch = useDispatch<AppDispatch>()

    const handleCancel = () => {
        setModal({ open: false, id: null, name: '', type: 'adding' })
    }

    const handleSubmit = async () => {

        if (name.length <= 2) {
            toast.warn('Unos mora imati bar 3 karaktera.')
            return
        }

        let resp;
        if (type === 'adding') {
            resp = await dispatch(addAdditionalService(name))
        } else {
            resp = await dispatch(editAdditionalService({ name, id }))
        }
        if (resp.meta.requestStatus === 'fulfilled') {
            handleCancel()
        }
    }



    return <div className="form-modal" >
        <h2>{type === 'adding' ? 'Dodavanje' : 'Menjanje'} dodatka</h2>
        <Input label="Naziv dodatka" name="name" value={name} handleChange={(e) => setName(e.target.value)} />
        <div className="form-modal__buttons" > <Button cancel text="Otkaži" onClick={handleCancel} /> <Button text="Sačuvaj" onClick={handleSubmit} />  </div>
    </div>
}

interface IFormModal {
    type: string
    id?: string
    value: string
    setModal: any
}