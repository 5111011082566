import { ISelectValue } from '../components/Select'
import { isValidPhoneNumber } from 'libphonenumber-js'
import React, { SetStateAction } from 'react'

export const minimumTwoCharacters = (value: string, name: string, setInvalidFields: any) => {
  setInvalidFields((prev: string[]) => {
    let copy = [...prev]
    if (value.length >= 2) {
      copy = copy.filter((i) => i !== name)
    } else {
      !copy.includes(name) && copy.push(name)
    }
    return copy
  })
}

export const minimumCharacters = (
  value: string,
  name: string,
  setInvalidFields: any,
  numChar: number,
) => {
  setInvalidFields((prev: string[]) => {
    let copy = [...prev]
    if (value.length >= numChar) {
      copy = copy.filter((i) => i !== name)
    } else {
      !copy.includes(name) && copy.push(name)
    }
    return copy
  })
}

export const greaterThanZero = (value: number, name: string, setInvalidFields: any) => {
  setInvalidFields((prev: string[]) => {
    let copy = [...prev]
    if (value > 0) {
      copy = copy.filter((i) => i !== name)
    } else {
      !copy.includes(name) && copy.push(name)
    }
    return copy
  })
}

export const validateDate = (name:string, setInvalidFields:any) => {
  setInvalidFields((prev:any[]) => {
    let copy = [...prev]
    copy = copy.filter((f) => f !== name)
    return copy
  })
}

export const validateSelect = (value: ISelectValue, name: string, setInvalidFields: any) => {
  setInvalidFields((prev: string[]) => {
    let copy = [...prev]
    if (value.value || (Array.isArray(value) && value.length > 0) || value.label === 'multiNone') {
      copy = copy.filter((i) => i !== name)
    } else {
      !copy.includes(name) && copy.push(name)
    }
    return copy
  })
}

export const hasValue = (value: string | number, name: string, setInvalidFields: any) => {
  let checkValue = value

  setInvalidFields((prev: string[]) => {
    let copy = [...prev]
    if (checkValue) {
      copy = copy.filter((i) => i !== name)
    } else {
      !copy.includes(name) && copy.push(name)
    }
    return copy
  })
}


const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
}

export const inComparisonValidation = (
  e: React.FormEvent<HTMLInputElement>,
  setInvalidFields: any,
  targetValue: number,
  isGreater: boolean,
) => {
  const { name, value } = e.target as HTMLInputElement
  setInvalidFields((prev:any) => {
    let copy = [...prev]

    if (isGreater && Number(value) > targetValue) {
      copy = copy.filter((f: string) => f !== name)
    } else if (!isGreater && Number(value) < targetValue) {
      copy = copy.filter((f: string) => f !== name)
    } else {
      copy.push(name)
    }
    return copy
  })
}

export const standardFieldValidation = (
  e: React.FormEvent<HTMLInputElement |HTMLTextAreaElement >,
  setInvalidFields: any,
) => {
  //@ts-ignore
  const { name, value,type } = e.target
 
  switch (type) {
    case 'textarea':
      minimumTwoCharacters(value, name, setInvalidFields)
      break
    case 'text':
      minimumTwoCharacters(value, name, setInvalidFields)
      break
    case 'email':
      setInvalidFields((prev: string[]) => {
        let copy = [...prev]
        if (validateEmail(value)) {
          copy = copy.filter((f) => f !== name)
        } else {
          !copy.includes(name) && copy.push(name)
          return copy
        }
        return copy
      })
      break
    case 'tel':
      setInvalidFields((prev: string[]) => {
        let copy = [...prev]
        if (isValidPhoneNumber(value)) {
          copy = copy.filter((f) => f !== name)
        } else {
          !copy.includes(name) && copy.push(name)
        }
        return copy
      })
      break
    default:
      setInvalidFields((prev: string[]) => {
        let copy = [...prev]
        if (!isNaN(Number(value)) ) {
          copy = copy.filter((f) => f !== name)
        } else {
          !copy.includes(name) && copy.push(name)
        }
        return copy
      })
      break
  }
}
