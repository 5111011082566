import axios from "axios";

const customFetch = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    "language":"sr"
  },
});

export const photosPost = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "content-type": "multipart/form-data",
    "language":"sr"
  },
});

customFetch.interceptors.request.use(
  config => {
    config.headers['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// api.interceptors.response.use(
//   (response) => {
//     if (response.config.url === '/v1/auth/verify-sms') {
//       token = response.data.data.tokens.accessToken;
//     }

//     return response;
//   },
//   function (error) {
//     const originalRequest = error.config;

//     if (
//       (error.response.status === 401 || error.response.status === 417) &&
//       originalRequest.url === '/v1/auth/refresh'
//     ) {
//       window.location.href = CMS_LOGIN;
//       return Promise.reject(error);
//     }

//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;

//       // console.log('REFRESH');

//       token = getRefreshToken();
//       return api.post('/v1/auth/refresh').then((res) => {
//         if (res.status === 200) {
//           setNewAccessToken(res.data.data.accessToken);
//           token = res.data.data.accessToken;

//           originalRequest.headers['Authorization'] =
//             'Bearer ' + res.data.data.accessToken;

//           // console.log(originalRequest);
//           return axios(originalRequest);
//         }
//       });
//     }
//     return Promise.reject(error);
//   }
// );

photosPost.interceptors.request.use(
  config => {
    config.headers['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export default customFetch;
