import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Button } from "../../components/Button"
import { Input } from "../../components/Input"
import { loginUser } from "../../features/user/user"
import { AppDispatch } from "../../store"
import BG1 from '../../assets/login/BG1.png'
import BG2 from '../../assets/login/BG2.png'
import BG3 from '../../assets/login/BG3.png'
import BG4 from '../../assets/login/BG4.png'
import Logo from '../../assets/Logo.svg'


export const Auth = () => {
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const [state, setState] = useState({
        email: '',
        password: ''
    })

    const user = localStorage.getItem('access_token')

    useEffect(() => {
        if (user) { navigate('/') }
    }, [user]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, prop: string) => {
        const { name, value } = e.target
        setState(prev => {
            const copy: any = structuredClone(prev)
            copy[name] = value
            return copy
        })
    }

    const handleSubmit = async () => {
        const resp = await dispatch(loginUser(state))
        if (resp.meta.requestStatus === 'fulfilled') {
            navigate('/requests')
        }
    }

    return <div className="auth" >
        <img className="logo" src={Logo} alt='' />
        <img className="top-right" src={BG1} alt='' />
        <img className="bottom" src={BG2} alt='' />
        <img className="left" src={BG3} alt='' />
        <img className="car" src={BG4} alt='' />
        <div className="auth__inputs" >
            <h1>Prijavljivanje</h1>
            <Input width="280px" name="email" type="email" label="Email" value={state.email} handleChange={handleChange} />
            <Input width="280px" name="password" type="password" label="Lozinka" value={state.password} handleChange={handleChange} />
            <Button style={{ width: '100%' }} text="Prijava" onClick={handleSubmit} />
        </div>
    </div>
}