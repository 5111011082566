export const months = [
    {
      label: "Januar",
      value: 0,
      days: 31,
    },
    {
      label: "Februar",
      value: 1,
      days: 28,
    },
    {
      label: "Mart",
      value: 2,
      days: 31,
    },
    {
      label: "April",
      value: 3,
      days: 30,
    },
    {
      label: "Maj",
      value: 4,
      days: 31,
    },
    {
      label: "Jun",
      value: 5,
      days: 30,
    },
    {
      label: "Jul",
      value: 6,
      days: 31,
    },
    {
      label: "Avgust",
      value: 7,
      days: 31,
    },
    {
      label: "Septembar",
      value: 8,
      days: 30,
    },
    {
      label: "Oktobar",
      value: 9,
      days: 31,
    },
    {
      label: "Novembar",
      value: 10,
      days: 30,
    },
    {
      label: "Decembar",
      value: 11,
      days: 31,
    },
  ];
  
  
  export const pickupReturnMethods = [{
    label:'Na Adresi agencije',
    value:1,
  },
  {
    label:'Na Adresi',
    value:2,
  },
  {
    label:'Na aerodromu',
    value:3,
  }]

  export const cities = [{
      label:'Beograd',
      value:'Belgrade'
  },{
    label:'Novi Sad',
    value:'Novi Sad'
},{
    label:'Nis',
    value:'Nis'
}]