import { AddressBookIcon } from "../assets/icons/AddressBookIcon";
import { CalendarIcon } from "../assets/icons/CalendarIcon";
import { CarIcon } from "../assets/icons/CarIcon";
import { FilePlusIcon } from "../assets/icons/FilePlusIcon";
import { PackageIcon } from "../assets/icons/PackageIcon";
import { RangeIcon } from "../assets/icons/RangeIcon";
import { SuitcaseIcon } from "../assets/icons/SuitcaseIcon";
import { AdditionalServices } from "../views/AdditionalServices/AdditionalServices";
import { AddAgency } from "../views/Agencies/AddAgency";
import { Agencies } from "../views/Agencies/Agencies";
import { EditAgency } from "../views/Agencies/EditAgency";
import { AddClient } from "../views/Clients/AddClient";
import { Clients } from "../views/Clients/Clients";
import { EditClient } from "../views/Clients/EditClient";
import { RentPeriods } from "../views/RentPeriods/RentPeriods";
import { Requests } from "../views/Requests/Requests";
import { AddReservation } from "../views/Reservations/AddReservation";
import { EditReservation } from "../views/Reservations/EditReservation";
import { Reservations } from "../views/Reservations/Reservations";
import { AddVehicle } from "../views/Vehicles/AddVehicle";
import { EditVehicle } from "../views/Vehicles/EditVehicle";
import { Vehicles } from "../views/Vehicles/Vehicles";

export const routes = [
  {
    path: "vehicles",
    element: Vehicles,
  },
  {
    path: "vehicles/add",
    element: AddVehicle,
  },
  {
    path: "vehicles/:id",
    element: EditVehicle,
  },
  {
    path: "reservations",
    element: Reservations,
  },
  {
    path: "reservations/add",
    element: AddReservation,
  },
  {
    path: "reservations/:id",
    element: EditReservation,
  },
  {
    path: "requests",
    element: Requests,
  },
  {
    path: "clients",
    element: Clients,
  },
  {
    path: "clients/add",
    element: AddClient,
  },
  {
    path: "clients/:id",
    element: EditClient,
  },
  {
    path: "agencies",
    element: Agencies,
  },
  {
    path: "agencies/add",
    element: AddAgency,
  },
  {
    path: "agencies/:id",
    element: EditAgency,
  },
  {
    path: "additional-services",
    element: AdditionalServices,
  },
  {
    path: "rent-periods",
    element: RentPeriods,
  },
];

export const navLinks = [
  // { name: "Dashboard", icon: GaugeIcon, path: "dashboard" },
  { name: "Zahtevi", icon: FilePlusIcon, path: "requests" },
  { name: "Rezervacije", icon: CalendarIcon, path: "reservations" },
  { name: "Vozila", icon: CarIcon, path: "vehicles" },
  { name: "Klijenti", icon: AddressBookIcon, path: "clients" },
  { name: "Agencije", icon: SuitcaseIcon, path: "agencies" },
  { name: "Dodatne usluge", icon: PackageIcon, path: "additional-services" },
  { name: "Raspon iznajmljivanja", icon: RangeIcon, path: "rent-periods" },
];
