import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Button } from "../../components/Button"
import { TableActions } from "../../components/TableActions"
import { clearVehicles, deleteVehicle, getVehicles } from "../../features/vehicles/vehiclesSlice"
import { AppDispatch, RootState } from "../../store"
import Filter from '../../assets/icons/Filter.svg'
import { VehiclesFilter } from "./VehiclesFilter"
import { parseFilters, vehicleFilters } from "./data"
import { ConfirmModal } from "../../components/ConfirmModal"

export const Vehicles = () => {

    const dispatch = useDispatch<AppDispatch>()
    const { vehicles } = useSelector((state: RootState) => state.vehicles)
    const [openFilter, setOpenFilter] = useState(false)
    const [filters, setFilters] = useState(vehicleFilters)
    const [deleteModal, setDeleteModal] = useState({ open: false, fn: null })
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(getVehicles(parseFilters(filters)))

        return (() => {
            dispatch(clearVehicles())
        })
    }, [filters])


    const handleDelete = (id: string) => {
        setDeleteModal({ open: true, fn: () => dispatch(deleteVehicle(id)) })
    }

    const handleCloseModal = () => {
        setDeleteModal({ open: false, fn: null })
    }


    return <div>
        <div className="page-top" style={{ flexDirection: 'column' }}  >
            <div className="page-top__upper-container" >
                <div onClick={() => setOpenFilter(prev => !prev)} className="filter-button"> <img src={Filter} alt='' /> <p>Filter</p></div>
                <Button text="Dodaj vozilo" onClick={() => navigate('add')} />
            </div>
            {openFilter && <VehiclesFilter setState={setFilters} state={filters} />}

        </div>
        <div className="table-wrapper" >

            <table>
                <thead>
                    <th >Marka</th>
                    <th >Model</th>
                    <th >Agencija</th>
                    <th >Menjac</th>
                    <th >Gorivo</th>
                    <th >Tip</th>
                </thead>
                <tbody></tbody>
                {vehicles.map((v, k) => <tr key={v.id}>
                    <td>{v.brand.name}</td>
                    <td>{v.model.name}</td>
                    <td>{v.agency.name}</td>
                    <td>{v.transmissionType.localizedText}</td>
                    <td>{v.fuelType.localizedText}</td>
                    <td>{v.vehicleType.localizedText}</td>
                    <TableActions zIndex={9 + k} options={[{ name: 'Izmeni', action: () => navigate(String(v.id)) }, { name: 'Obriši', action: () => handleDelete(String(v.id)) }]} />
                </tr>)}

            </table>
        </div>
        {deleteModal.open && <ConfirmModal text="obrišete vozilo?" onYes={deleteModal.fn} closeModal={handleCloseModal} />}

    </div>
}