import { Button } from "./Button"
import QuestionMark from '../assets/icons/QuestionMark.svg'

export const ConfirmModal = ({ text, onYes, closeModal }: IConfirmModal) => {


    const handleConfirm = async () => {
        const resp = await onYes()
        if (resp.meta.requestStatus === 'fulfilled') {
            closeModal()
        }
    }

    return <div className="confirm-modal" >
        <div>
            <p>Da li ste sigurni da želite da {text}</p>
        </div>
        <img src={QuestionMark} alt="" />
        <div className="confirm-modal__buttons" >
            <Button cancel onClick={closeModal} text="Otkaži" />
            <Button text="Potvrdi" onClick={handleConfirm} />
        </div>
    </div>
}

interface IConfirmModal {
    text: string
    onYes: () => any
    closeModal: () => any
}