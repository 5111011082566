import React from "react"

export const TextArea = ({ handleChange, value, label, placeholder, name }: ITextArea) => {

    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        handleChange(e, name)
    }

    return <div className="input-container" style={{ width: '100%', marginTop: '0', marginBottom: '1rem' }} >
        {label && <p className="input-container__label" >{label}</p>}
        <div className="input-container__input" style={{ height: '110px' }} >
            <textarea name={name} required value={value} onChange={onChange} placeholder={placeholder ?? 'Unesite ovde'} />
        </div>
    </div>
}

interface ITextArea {
    handleChange: (value: React.ChangeEvent<HTMLTextAreaElement>, name: string) => any
    value: string
    label: string
    placeholder?: string
    name: string
}