import Logo from "../assets/Logo.svg";
import { Link, useLocation } from "react-router-dom";
import { navLinks } from "../utils/routes";
import { PowerIcon } from "../assets/icons/PowerIcon";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store";
import { logOutUser } from "../features/user/user";

export const Nav = () => {
    const location = useLocation();
    const dispatch = useDispatch<AppDispatch>()


    const handleLogout = () => {
        dispatch(logOutUser())
    }

    const renderLinks = () => {
        const getClassName = (name: string) => {
            if (location.pathname.includes(name)) {
                return "active-link";
            }
            return "";
        };

        return navLinks.map((l, k) => (
            <li key={k} className={getClassName(l.path as string)}>
                <Link to={l.path as string} >
                    {<l.icon fill={getClassName(l.path as string) ? "#F5C001" : ""} />}
                    <p style={getClassName(l.path as string) ? { color: "white" } : {}} >{l.name}</p>
                </Link>
            </li>
        ));
    };

    return (
        <nav>
            <img src={Logo} alt="" />
            <ul>{renderLinks()}
                <li onClick={handleLogout} ><PowerIcon /><p>Odjava</p></li>
            </ul>

        </nav>
    );
};
