import Check from '../assets/icons/Check.svg'
import { Input } from './Input'

export const CheckText = ({ checked, name, input, handleChange, value, id, handleCheck, width }: ICheckText) => {
    return <div className="check-text" style={{ width }} >
        <div className='check-text__main-wrapper' >
            <div className="check-text__check" onClick={() => handleCheck(id)} style={checked ? { backgroundColor: '#ffbb00' } : {}} >
                {checked && <img alt="" src={Check} />}
            </div>
            <p>{name}</p>
        </div>
        {input && <Input id={id} name='id' type='number' value={value} handleChange={handleChange} width='60px' placeholder='0' />}
    </div>

}

interface ICheckText {
    checked: boolean
    id: string
    name: string
    input?: boolean
    handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => any
    value?: string | number
    handleCheck?: (id: string) => any
    width?: string
}