import React, { CSSProperties } from "react"

export const Input = ({ handleChange, value, label, placeholder, type, name, width, invisible, id }: IInput) => {

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleChange(e, name)
    }
    const inv: CSSProperties = invisible ? { opacity: 0, userSelect: 'none', pointerEvents: 'none', minWidth: width, maxWidth: width } : { minWidth: width, maxWidth: width }

    const parsedValue = (type && type === 'number') ? String(value) === '0' ? 0 : value : value

    return <div className="input-container" style={{ width, ...inv }} >
        {label && <p className="input-container__label" >{label}</p>}
        <div className="input-container__input"  >
            <input id={id} required type={type ?? 'text'} name={name} value={parsedValue} onChange={onChange} placeholder={placeholder ?? 'Unesite'} />
        </div>
    </div>
}

interface IInput {
    handleChange: (value: React.ChangeEvent<HTMLInputElement>, name: string) => any
    value: string | number
    label?: string
    placeholder?: string
    type?: string
    name: string
    width?: string
    invisible?: boolean
    id?: string
}