import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Button } from "../../components/Button"
import { ConfirmModal } from "../../components/ConfirmModal"
import { TableActions } from "../../components/TableActions"
import { clearClients, deleteClient, getClients } from "../../features/clients/clientsSlice"
import { AppDispatch, RootState } from "../../store"

export const Clients = () => {

    const { clients } = useSelector((state: RootState) => state.clients)
    const [deleteModal, setDeleteModal] = useState({ open: false, fn: null })

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    useEffect(() => {
        dispatch(getClients())

        return (() => {
            dispatch(clearClients())
        })
    }, [])


    const handleDelete = (id: number) => {
        setDeleteModal({ open: true, fn: () => dispatch(deleteClient(id)) })
    }

    const handleCloseModal = () => {
        setDeleteModal({ open: false, fn: null })
    }


    return <div>

        <div className="page-top" style={{ justifyContent: 'flex-end' }}  >
            <Button text="Dodaj klijenta" onClick={() => navigate('add')} />
        </div>

        <div className="table-wrapper" >
            <table >
                <thead >
                    <th>Ime</th>
                    <th  >Prezime </th>
                    <th >Broj telefona</th>
                    <th  >Email</th>
                    <th >Broj vozacke</th>
                </thead>
                <tbody>
                    {clients.map((c, k) => <tr key={c.id} >
                        <td>{c.firstName}</td>
                        <td>{c.lastName}</td>
                        <td>{c.phoneNumber}</td>
                        <td>{c.email}</td>
                        <td>{c.licenseNumber}</td>
                        <TableActions top={clients.length - 1 === k} zIndex={9 + k} options={[{ name: 'Izmeni', action: () => navigate(String(c.id)) }, { name: 'Obriši', action: () => handleDelete(c.id) }]} />
                    </tr>)}
                </tbody>
            </table>
        </div>
        {deleteModal.open && <ConfirmModal text="obrišete klijenta?" onYes={deleteModal.fn} closeModal={handleCloseModal} />}

    </div>
}