export const clientsInitialState = {
    firstName:'',
    lastName:'',
    phoneNumber:'',
    licenseNumber:'',
    email:''
}

export type clientState = typeof clientsInitialState

export const invalidClientsFields = Object.keys(clientsInitialState)
