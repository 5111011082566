import { CSSProperties, useRef, useState } from "react";
import Chevron from '../assets/icons/Chevron.svg'

export default function Select({
    label,
    options,
    handleChange,
    value,
    placeholder,
    top,
    name,
    width,
    invisible
}: ISelect) {
    const [openOptions, setOpenOptions] = useState(false);
    const ref = useRef(null)
    const handleSelect = (o: ISelectValue) => {
        handleChange(o, name)
        setOpenOptions(false)
    }

    const inv: CSSProperties = invisible ? { opacity: 0, userSelect: 'none', pointerEvents: 'none' } : {}
    return (
        <div className="input-container" style={{ width, ...inv, cursor: 'pointer' }} tabIndex={0} onBlur={() => setOpenOptions(false)} >
            {label && <p className="input-container__label" >{label}</p>}
            <div className="input-container__input" ref={ref} onClick={() => setOpenOptions(true)} >
                {!value || !value.value ? <p className="placeholder" >{placeholder ?? 'Odaberite'}</p> : <p className="input-container__input--value" >{value.label}</p>}
                <img src={Chevron} alt="" />
            </div>
            {openOptions && (
                <div className="select-options-container" style={top ? { bottom: '105%', top: 'unset' } : {}} >
                    {options.map((o, k) => (
                        <div className="select-option" onClick={() => handleSelect(o)} key={k}>
                            {o.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

interface ISelect {
    label?: string;
    options: ISelectValue[];
    handleChange: (o: ISelectValue, name: string) => any;
    value: ISelectValue | null;
    placeholder?: string
    top?: boolean
    name: string
    width?: string
    invisible?: boolean
}

export interface ISelectValue {
    label: string | number;
    value: string | number;
}
