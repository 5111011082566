import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch from "../../utils/axios";
import { toast } from "react-toastify";
import { IGetSingleRequest } from "../../types/requests.interface";
import { handleErrors } from "../../utils/helpers";

const initialState:IState = {
  isLoading: false,
  requests: [],
};

interface IState{
    isLoading:boolean
    requests:IGetSingleRequest[]
}

export const archiveRequest = createAsyncThunk(
  "requests/archive",
  async (id:number, thunkApi) => {
    try {
    await customFetch.patch("car-requests/"+id+'/archive');
      return id;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteRequest = createAsyncThunk(
  "requests/delete",
  async (id:number, thunkApi) => {
    try {
      await customFetch.delete("car-requests/"+id);
      return id;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getRequests = createAsyncThunk(
  "requests/getAll",
  async (_, thunkApi) => {
    try {
      const resp = await customFetch.get("car-requests");
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const requestsSlice = createSlice({
  name: "requests",
  initialState,
  reducers: {
    clearRequests:(state:IState)=>{
      state.requests = []
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getRequests.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRequests.fulfilled, (state, { payload }: any) => {
        state.isLoading = false;
        state.requests = payload
      })
      .addCase(getRequests.rejected, (state, {payload}: any) => {
        state.isLoading = false;
        handleErrors(payload)
      })
      .addCase(deleteRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteRequest.fulfilled, (state, { payload }: any) => {
        state.isLoading = false;
        state.requests = state.requests.filter(r=>r.id!==payload)
        toast.success('Zahtev uspešno obrisan.')
      })
      .addCase(deleteRequest.rejected, (state, {payload}: any) => {
        state.isLoading = false;
        handleErrors(payload)
      })
      .addCase(archiveRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(archiveRequest.fulfilled, (state, { payload }: any) => {
        state.isLoading = false;
        state.requests = state.requests.filter(r=>r.id!==payload)
        toast.success('Zahtev uspešno arhiviran.')
      })
      .addCase(archiveRequest.rejected, (state, {payload}: any) => {
        state.isLoading = false;
        handleErrors(payload)
      });
  },
});

export const {clearRequests} = requestsSlice.actions

export default requestsSlice.reducer;
