import { CSSProperties } from "react"

export const TextDisplay = ({ text, label, width, invisible, textCenter }: ITextDisplay) => {

    const inv: CSSProperties = invisible ? { opacity: 0, userSelect: 'none', pointerEvents: 'none' } : {}
    const center: CSSProperties = textCenter ? { width: '100%', textAlign: 'center' } : {}
    return <div className="input-container" style={width ? { width, ...inv } : { ...inv }} >
        {label && <p className="input-container__label" >{label}</p>}
        <div className="input-container__input"  >
            <p className="input-container__input--value" style={{ margin: 'unset', fontSize: '14px', fontWeight: 400, ...center }} >{text}</p>
        </div>
    </div>
}

interface ITextDisplay {
    text?: string | number
    label?: string
    width?: string
    invisible?: boolean
    textCenter?: boolean
}