import { useClickAway } from '@uidotdev/usehooks';
import { useState } from 'react'
import ThreeDots from '../assets/icons/ThreeDots.svg'


export const TableActions = ({ options, zIndex, top }: ITableACtions) => {

    const [open, setOpen] = useState(false)

    const ref: any = useClickAway(() => {
        setOpen(false);
    });

    const topStyle = top ? { top: 'unset', bottom: '20%' } : {}

    return <div className="actions" ref={ref} ><img src={ThreeDots} alt='' onClick={() => setOpen(true)} />
        {open && <div className="actions__options" style={{ zIndex, ...topStyle }} >
            {options.map((o, k) => <div onClick={o.action} key={k} >{o.name}</div>)}
        </div>}
    </div>

}

interface ITableACtions {
    options: {
        name: string
        action: () => any
    }[]
    zIndex?: number
    top?: boolean
}