import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.scss';
import { ProtectedRoute } from './views/ProtectedRoute';
import { SharedLayout } from './views/SharedLayout';
import { routes } from './utils/routes';
import { Error } from './views/Error';
import 'react-toastify/dist/ReactToastify.css'
import { Slide, ToastContainer } from 'react-toastify'
import { Auth } from './views/Login/Auth';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css filer
import { Loader } from './components/Loader';


function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route
            path='/'
            element={
              <ProtectedRoute>
                <SharedLayout />
              </ProtectedRoute>
            }
          >
            {routes.map((r, i) => <Route key={i} path={r.path} element={<r.element />} />)}
            <Route path='*' element={<Error />} />
          </Route>
          <Route path='auth' element={<Auth />} />
        </Routes>
      </Router>
      <Loader />

      <ToastContainer
        position='top-center'
        autoClose={2000}
        theme={'dark'}
        hideProgressBar={false}
        newestOnTop={false}
        limit={2}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        style={{ borderColor: 'red', zIndex: 99999999999999 }}
        transition={Slide}
        draggable
        pauseOnHover
      /></>
  );
}

export default App;
