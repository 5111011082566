import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch, { photosPost } from "../../utils/axios";
import { toast } from "react-toastify";
import { IEditVehiclePayload, IGetSingleVehicle } from "../../types/vehicles.interface";
import { handleErrors, sortRentPeriodSeasons } from "../../utils/helpers";
import { IVehiclesState } from "../../views/Vehicles/data";
import { RootState } from "../../store";
import { IGetSingleRentPeriod } from "../../types/rent-periods.interface";

const initialState:IState = {
  isLoading: false,
  vehicles: [],
  singleVehicle:null
};

interface IState{
  isLoading:boolean
  vehicles:IGetSingleVehicle[]
  singleVehicle:IVehiclesState
}

// /vehicle-agency-season-rent-period-price

export const getVehicles = createAsyncThunk(
  "vehicles/getAll",
  async (params:any, thunkApi) => {
    try {
      const resp = await customFetch.get("vehicle",{params});
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteVehicle = createAsyncThunk(
  "vehicles/delete",
  async (id:string, thunkApi) => {
    try {
     await customFetch.delete("vehicle/"+id);
      return Number(id);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);


export const getSingleVehicle = createAsyncThunk(
  "vehicles/getSingle",
  async (id:string, thunkApi) => {
    try {

      const images = await customFetch.get('vehicle-storage/'+id)

      const resp = await customFetch.get("vehicle/"+id);
      const rentPeriods = await customFetch.get('rent-period')

      const vehicleData:IGetSingleVehicle = resp.data
      const rentPeriodsData: IGetSingleRentPeriod[] = rentPeriods.data

      const formattedForState:IVehiclesState ={
        agency:{label:vehicleData.agency.name,value:vehicleData.agency.id},
        brand:{label:vehicleData.brand.name,value:vehicleData.brand.id},
        mileage:{label:vehicleData.mileage.localizedText,value:vehicleData.mileage.id},
        model:{label:vehicleData.model.name,value:vehicleData.model.id},
        transmissionType:{label:vehicleData.transmissionType.localizedText,value:vehicleData.transmissionType.id},
        fuelType:{label:vehicleData.fuelType.localizedText,value:vehicleData.fuelType.id},
        // vehicleClass:{label:vehicleData.vehicleClass.localizedText,value:vehicleData.vehicleClass.id},
        vehicleType:{label:vehicleData.vehicleType.localizedText,value:vehicleData.vehicleType.id},
        year:{label:vehicleData.year,value:vehicleData.year},
        doors:{label:vehicleData.numberOfDoors,value:vehicleData.numberOfDoors},
        seats:{label:vehicleData.numberOfSeats,value:vehicleData.numberOfSeats},
        seasons:[...vehicleData.agency.agencySeasons.map(s=>({name:s.season.name,seasonId:s.id,rentPeriods:sortRentPeriodSeasons(rentPeriodsData.map(r=>({...r,prevId:vehicleData.vehicleAgencySeasonRentPeriodPrices.find(rpp=>rpp.agencySeason?.seasonId === s.season.id && rpp.rentPeriod.id === r.id)?.id,price:vehicleData.vehicleAgencySeasonRentPeriodPrices.find(rpp=>rpp.agencySeason?.seasonId === s.season.id && rpp.rentPeriod.id === r.id)?.price || null})))})),{name:'Van sezone', seasonId:null,rentPeriods:sortRentPeriodSeasons(rentPeriodsData.map(r=>({...r,prevId:vehicleData.vehicleAgencySeasonRentPeriodPrices.find(rpp=>!rpp.agencySeason && rpp.rentPeriod.id === r.id)?.id,price:vehicleData.vehicleAgencySeasonRentPeriodPrices.find(rpp=>!rpp.agencySeason && rpp.rentPeriod.id === r.id )?.price})))}],
        description:vehicleData.description,
        deposit:vehicleData.deposit,
        images:images.data
      }

      return formattedForState
    } catch (error) {
      console.log(error)
      return thunkApi.rejectWithValue(error);
    }
  }
);


export const addVehicle = createAsyncThunk(
  "vehicles/add",
  async (vehicle:IVehiclesState, thunkApi) => {
    const obj = {
      brandId:vehicle.brand.value,
      modelId:vehicle.model.value,
      year:vehicle.year.value,
      agencyId:vehicle.agency.value,
      numberOfSeats:vehicle.seats.value,
      transmissionTypeId: vehicle.transmissionType.value,
      fuelTypeId: vehicle.fuelType.value,
      numberOfDoors: vehicle.doors.value,
      mileageId: vehicle.mileage.value,
      vehicleTypeId: vehicle.vehicleType.value,
      // vehicleClassId: vehicle.vehicleClass.value,
      deposit: Number(vehicle.deposit),
      isOnActionPrice: false,
      description: vehicle.description
    }
    try {
      const resp = await customFetch.post("vehicle",obj);

      for( const s of vehicle.seasons){
        for(const rp of s.rentPeriods){
          if(rp.price){
            const rpObj = {
              vehicleId:resp.data.id,
              agencySeasonId:s.seasonId === 'null' ? null : s.seasonId,
              rentPeriodId:rp.id,
              price:rp.price
            }
            await customFetch.post("vehicle-agency-season-rent-period-price",rpObj);
          }
        }
      }

      if (vehicle.images.length > 0) {
        const data = new FormData();
        //@ts-ignore
        for(const img of vehicle.images){
          data.append("files", img);
        }
        //@ts-ignore
        await photosPost.post('vehicle-storage/'+resp.data.id,data, data)
      }

      return resp.data;
    } catch (error) {
      console.log(error)
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const editVehicle = createAsyncThunk(
  "vehicles/edit",
  async ({vehicle, id}:IEditVehiclePayload, thunkApi) => {
    const obj = {
      brandId:vehicle.brand.value,
      modelId:vehicle.model.value,
      year:vehicle.year.value,
      agencyId:vehicle.agency.value,
      numberOfSeats:vehicle.seats.value,
      transmissionTypeId: vehicle.transmissionType.value,
      fuelTypeId: vehicle.fuelType.value,
      numberOfDoors: vehicle.doors.value,
      mileageId: vehicle.mileage.value,
      vehicleTypeId: vehicle.vehicleType.value,
      // vehicleClassId: vehicle.vehicleClass.value,
      deposit: Number(vehicle.deposit),
      isOnActionPrice: false,
      description: vehicle.description
    }
    try {
      const resp = await customFetch.patch("vehicle/"+id,obj);
      for( const s of vehicle.seasons){
        for(const rp of s.rentPeriods){
          const oldRentPeriod = (thunkApi.getState() as RootState).vehicles.singleVehicle.seasons.find(os=>os.seasonId===s.seasonId).rentPeriods.find(r=>r.id===rp.id)
          if(oldRentPeriod?.price !== rp?.price ){
            if(!rp.price){
              await customFetch.delete('/vehicle-agency-season-rent-period-price/'+rp.prevId,)
            }else if(!rp.prevId){ // za slucaj da rent period nije prethodno unet u tu sezonu na kreiranju vozila
              await customFetch.post('/vehicle-agency-season-rent-period-price',{
                vehicleId:Number(id),
                agencySeasonId:s.seasonId,
                rentPeriodId:rp.id,
                price:rp.price
              })
            }else{
              //@ts-ignore
              await customFetch.patch('/vehicle-agency-season-rent-period-price/'+rp.prevId ?? rp.id,{price:rp.price})
            }
          }
        }
      }



      if (vehicle.images.length > 0) {
        const data = new FormData();
        //@ts-ignore
        data.append("files", vehicle.images);
        //@ts-ignore
        await photosPost.post('vehicle-storage/'+resp.data.id,data, data)
      }

      return resp.data;
    } catch (error) {
      console.log(error)
      return thunkApi.rejectWithValue(error);
    }
  }
);

const vehiclesSlice = createSlice({
  name: "vehicles",
  initialState,
  reducers: {
    clearVehicles:(state:IState)=>{
      state.vehicles = []
      state.singleVehicle = null
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getVehicles.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVehicles.fulfilled, (state, { payload }: any) => {
        state.isLoading = false;
        state.vehicles = payload
      })
      .addCase(getVehicles.rejected, (state, {payload}: any) => {
        state.isLoading = false;
        handleErrors(payload)
      })
      .addCase(editVehicle.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editVehicle.fulfilled, (state, { payload }: any) => {
        state.isLoading = false;
        toast.success('Vozilo uspešno izmenjeno.')
      })
      .addCase(editVehicle.rejected, (state, {payload}: any) => {
        state.isLoading = false;
        handleErrors(payload)
      })
      .addCase(deleteVehicle.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteVehicle.fulfilled, (state, { payload }: any) => {
        state.vehicles = state.vehicles.filter(v=>v.id!==payload)
        toast.success('Vozilo uspešno obrisano.')
        state.isLoading = false;
      })
      .addCase(deleteVehicle.rejected, (state, {payload}: any) => {
        state.isLoading = false;
        handleErrors(payload)
      })
      .addCase(getSingleVehicle.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSingleVehicle.fulfilled, (state, { payload }: any) => {
        state.isLoading = false;
        state.singleVehicle = payload
      })
      .addCase(getSingleVehicle.rejected, (state, {payload}: any) => {
        state.isLoading = false;
        handleErrors(payload)
      })
      .addCase(addVehicle.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addVehicle.fulfilled, (state, { payload }: any) => {
        state.isLoading = false;
        state.vehicles.push(payload)
        toast.success('Vozilo uspešno dodato.')
      })
      .addCase(addVehicle.rejected, (state, {payload}: any) => {
        state.isLoading = false;
        handleErrors(payload)
      })
  },
});

export const {clearVehicles} = vehiclesSlice.actions

export default vehiclesSlice.reducer;
