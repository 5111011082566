import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import AsyncSelect from "../../components/AsyncSelect"
import { Button } from "../../components/Button"
import { DateRangePicker } from "../../components/DateRangePicker"
import { Input } from "../../components/Input"
import { ISelectValue } from "../../components/Select"
import { addAgency } from "../../features/agencies/agenciesSlice"
import { getSeasons } from "../../features/side-data/sideDataSlice"
import { AppDispatch, RootState } from "../../store"
import { ILocalizedTextId } from "../../types/global.interface"
import { changeState } from "../../utils/helpers"
import { standardFieldValidation, validateSelect } from "../../utils/validationUtils"
import { AdditionalServicesModal } from "./AdditionalServicesModal"
import { agenciesInitialState, agenciesInvalidFields } from "./data"
import { MileageModal } from "./MileageModal"

export const AddAgency = () => {

    const [state, setState] = useState(agenciesInitialState)
    const [mileageModal, setMileageModal] = useState(false)
    const [additionalServicesModal, setAdditionalServicesModal] = useState(false)
    const [invalidFields, setInvalidFields] = useState(agenciesInvalidFields)
    const { seasons } = useSelector((state: RootState) => state.sideData)
    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(getSeasons())
    }, [])


    console.log(invalidFields)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        standardFieldValidation(e, setInvalidFields)
        changeState(setState, name, value)
    }

    const handleSubmit = async () => {
        if (invalidFields.length > 0) {
            toast.warn(invalidFields[0])
            // toast.warn('Sva osnovna polja moraju biti popunjena i validna.')
            return
        }
        const resp = await dispatch(addAgency(state))
        if (resp.meta.requestStatus === 'fulfilled') {
            navigate('/agencies')
        }
    }

    const handleSelect = (value: ISelectValue, name: string) => {
        validateSelect(value, name, setInvalidFields)
        changeState(setState, name, value)
    }


    const handleDate = (fromDate: Date, toDate: Date, id: number) => {
        const index = state.seasons.findIndex(s => s.id === id)
        setState(prev => {
            const copy = structuredClone(prev)
            if (index < 0) {
                copy.seasons.push({
                    id,
                    fromDate,
                    toDate,
                    name: seasons.find(season => season.id === id).name
                })
            } else {
                copy.seasons[index] = { ...copy.seasons[index], toDate, fromDate }
            }
            return copy
        })
    }

    const renderSeasons = () => {
        return seasons.map(s => {

            const found = state.seasons.find(as => as.id === s.id)

            return <DateRangePicker key={s.id} handleDate={handleDate} id={s.id} label={s.name} fromDate={found?.fromDate} toDate={found?.toDate} />
        })
    }

    return <div className="form-page" >
        <h3 className="form-subtitle" >AGENCIJA</h3>
        <div className="form-grid" >
            <Input value={state.name} name='name' label='Naziv' handleChange={handleChange} />
            <Input value={state.phoneNumber} name='phoneNumber' type="tel" label='Broj telefona' handleChange={handleChange} />
            <Input value={state.email} name='email' type="email" label='Email' handleChange={handleChange} />
            <Input value={state.address} name='address' label='Adresa' handleChange={handleChange} />
            <Input value={state.city} name='city' label='Grad' handleChange={handleChange} />
            <Input value={state.representative} name='representative' label='Zastupnik' handleChange={handleChange} />
            <Input value={state.pib} type='number' name='pib' label='PIB' handleChange={handleChange} />
            <Button text="Dodaci" onClick={() => setAdditionalServicesModal(true)} />
            <Button text="Raspon i kilometraža" onClick={() => setMileageModal(true)} />
        </div>
        <h3 className="form-subtitle" >SEZONE</h3>
        <div className="form-grid" >
            {renderSeasons()}
        </div>
        <h3 className="form-subtitle" >OSIGURANJA</h3>
        <div className="form-grid" >
            <AsyncSelect parserFunction={(r: ILocalizedTextId[]) => r.map(i => ({ label: i.localizedText, value: i.id }))} handleChange={handleSelect} link="insurance-type" label="Osiguranje" name="insurance" value={state.insurance} />
            <Input handleChange={handleChange} label="Doplata" name="surcharge" type="number" value={state.surcharge} />
        </div>
        <h3 className="form-subtitle" >PODEŠAVANJA</h3>
        <div className="form-grid" >
            <Input handleChange={handleChange} label="Cena po kilometru preko eur cent" name="additionalPricePerKilometer" type="number" value={state.additionalPricePerKilometer} />
        </div>
        <div className="page-bottom" >
            <Button text="Sačuvaj" onClick={handleSubmit} />
        </div>
        {mileageModal && <MileageModal setState={setState} rentPeriodMileagesState={state.rentPeriodMileage} closeModal={() => setMileageModal(false)} />}
        {additionalServicesModal && <AdditionalServicesModal setState={setState} state={state} closeModal={() => setAdditionalServicesModal(false)} />}
    </div>
}