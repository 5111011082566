import { configureStore } from "@reduxjs/toolkit";
import additionalServicesSlice from "./features/additional-services/additionalServicesSlice";
import agenciesSlice from "./features/agencies/agenciesSlice";
import clientsSlice from "./features/clients/clientsSlice";
import rentPeriodsSlice from "./features/rent-periods/rentPeriodsSlice";
import requestsSlice from "./features/requests/requestsSlice";
import reservationsSlice from "./features/reservations/reservationsSlice";
import sideDataSlice from "./features/side-data/sideDataSlice";
import userSlice from "./features/user/user";
import vehiclesSlice from "./features/vehicles/vehiclesSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    reservations:reservationsSlice,
    requests:requestsSlice,
    vehicles:vehiclesSlice,
    clients:clientsSlice,
    agencies:agenciesSlice,
    additionalServices:additionalServicesSlice,
    sideData:sideDataSlice,
    rentPeriods:rentPeriodsSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
