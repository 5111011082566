import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch from "../../utils/axios";
import { toast } from "react-toastify";
import { handleErrors } from "../../utils/helpers";
import { IGetSingleRentPeriod } from "../../types/rent-periods.interface";
import { IRentPeriodsState } from "../../views/RentPeriods/data";

const initialState:IState = {
  isLoading: false,
  rentPeriods: [],
  singleRentPeriod:null
};

interface IState{
  isLoading:boolean
  rentPeriods:IGetSingleRentPeriod[]
  singleRentPeriod:IGetSingleRentPeriod
}

export const getRentPeriods = createAsyncThunk(
    "rentPeriods/getAll",
    async (_, thunkApi) => {
      try {
        const resp = await customFetch.get("rent-period");
        return resp.data;
      } catch (error) {
        return thunkApi.rejectWithValue(error);
      }
    }
  );

  export const getSingleRentPeriod = createAsyncThunk(
    "rentPeriods/getSingle",
    async (id:string, thunkApi) => {
      try {
        const resp = await customFetch.get("rent-period/"+id);
        return resp.data;
      } catch (error) {
        return thunkApi.rejectWithValue(error);
      }
    }
  );

  export const addRentPeriod = createAsyncThunk(
    "rentPeriods/add",
    async (rentPeriod:IRentPeriodsState, thunkApi) => {
      try {
        const resp = await customFetch.post("rent-period/",rentPeriod);
        return resp.data;
      } catch (error) {
        return thunkApi.rejectWithValue(error);
      }
    }
  );

  export const editRentPeriod = createAsyncThunk(
    "rentPeriods/edit",
    async (rentPeriod:IRentPeriodsState, thunkApi) => {
      try {
        const resp = await customFetch.patch("rent-period/"+rentPeriod.id,rentPeriod);
        return resp.data;
      } catch (error) {
        return thunkApi.rejectWithValue(error);
      }
    }
  );

  export const deleteRentPeriod = createAsyncThunk(
    "rentPeriods/delete",
    async (id:number, thunkApi) => {
      try {
        await customFetch.delete("rent-period/"+id);
        return id;
      } catch (error) {
        return thunkApi.rejectWithValue(error);
      }
    }
  );


const rentPeriodsSlice = createSlice({
  name: "rentPeriods",
  initialState,
  reducers: {
    clearRentPeriods:(state:IState)=>{
      state.rentPeriods =[]
    }
  },
  extraReducers(builder) {
    builder
    .addCase(getRentPeriods.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRentPeriods.fulfilled, (state, { payload }: any) => {
        state.isLoading = false;
        state.rentPeriods = payload;
      })
      .addCase(getRentPeriods.rejected, (state, { payload }: any) => {
        state.isLoading = false;
        handleErrors(payload);
      })
      .addCase(getSingleRentPeriod.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSingleRentPeriod.fulfilled, (state, { payload }: any) => {
        state.isLoading = false;
        state.singleRentPeriod = payload;
      })
      .addCase(getSingleRentPeriod.rejected, (state, { payload }: any) => {
        state.isLoading = false;
        handleErrors(payload);
      })
      .addCase(addRentPeriod.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addRentPeriod.fulfilled, (state,{payload}) => {
        state.isLoading = false;
        state.rentPeriods.push(payload)
        toast.success('Raspon uspešno sačuvan.')
      })
      .addCase(addRentPeriod.rejected, (state, { payload }: any) => {
        state.isLoading = false;
        handleErrors(payload);
      })
      .addCase(editRentPeriod.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editRentPeriod.fulfilled, (state,{payload}) => {
        state.isLoading = false;
        const index = state.rentPeriods.findIndex(r=>r.id === payload.id)
        state.rentPeriods[index] = payload
        toast.success('Raspon uspešno izmenjen.')
      })
      .addCase(editRentPeriod.rejected, (state, { payload }: any) => {
        state.isLoading = false;
        handleErrors(payload);
      })
      .addCase(deleteRentPeriod.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteRentPeriod.fulfilled, (state,{payload}) => {
        state.isLoading = false;
        state.rentPeriods = state.rentPeriods.filter(r=>r.id!==payload)
        toast.success('Raspon uspešno obrisan.')
      })
      .addCase(deleteRentPeriod.rejected, (state, { payload }: any) => {
        state.isLoading = false;
        handleErrors(payload);
      })
  },
});

export const {clearRentPeriods} = rentPeriodsSlice.actions

export default rentPeriodsSlice.reducer;
