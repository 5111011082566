import { useRef } from "react";
import Image from '../assets/icons/Image.svg'

export const ImagePick = ({ onChange, src, name }: IImagePick) => {
    const ref = useRef(null)

    const getImage = (src) => {
        try {
            return URL.createObjectURL(src)
        } catch (error) {
            return src.signedUrl
        }
    }

    return <div className="image-picker" onClick={() => ref.current.click()}>
        {src ? <img alt="" className="image" src={getImage(src)} /> : <img alt="" src={Image} />}
        <input
            hidden
            name={name}
            type="file"
            accept="image/*"
            ref={ref}
            onChange={(e) => {
                onChange(e.target.files[0], name);
            }}
        />
    </div>
}

interface IImagePick {
    onChange: (file: any, name: string) => any
    src: any
    name: string
}