import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Button } from "../../components/Button"
import { ConfirmModal } from "../../components/ConfirmModal"
import { FilterSelect } from "../../components/FilterSelect"
import { TableActions } from "../../components/TableActions"
import { clearAgencies, deleteAgency, getAgencies } from "../../features/agencies/agenciesSlice"
import { AppDispatch, RootState } from "../../store"

export const Agencies = () => {

    const { agencies } = useSelector((state: RootState) => state.agencies)
    const [deleteModal, setDeleteModal] = useState({ open: false, fn: null })
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    useEffect(() => {
        dispatch(getAgencies())

        return (() => {
            dispatch(clearAgencies())
        })
    }, [])

    const handleDelete = (id: number) => {
        setDeleteModal({ open: true, fn: () => dispatch(deleteAgency(id)) })
    }

    const handleCloseModal = () => {
        setDeleteModal({ open: false, fn: null })
    }

    return <div>

        <div className="page-top" style={{ justifyContent: 'flex-end' }} >
            {/* <FilterSelect value={{ label: '', value: '' }} label="Svi zahtevi" name="type" handleChange={() => null} options={[{ label: 'Test', value: 'Primer' }, { label: 'Test', value: 'Primer' }, { label: 'Test', value: 'Primer' }, { label: 'Test', value: 'Primer' }, { label: 'Test', value: 'Primer' }, { label: 'Test', value: 'Primer' }, { label: 'Test', value: 'Primer' }, { label: 'Test', value: 'Primer' }, { label: 'Test', value: 'Primer' }, { label: 'Test', value: 'Primer' }, { label: 'Test', value: 'Primer' }, { label: 'Test', value: 'Primer' }, { label: 'Test', value: 'Primer' }, { label: 'Test', value: 'Primer' }, { label: 'Test', value: 'Primer' },]} /> */}
            <Button text="Dodaj agenciju" onClick={() => navigate('add')} />
        </div>
        <div className="table-wrapper" >

            <table >
                <thead >
                    <th>Naziv</th>
                    <th  >Grad </th>
                    <th >Adresa</th>
                    <th  >Zastupnik</th>
                    <th >Telefon</th>
                    <th >Email</th>
                </thead>
                <tbody>
                    {agencies.map((a, k) => <tr key={a.id} >
                        <td>{a.name}</td>
                        <td>{a.city}</td>
                        <td>{a.address}</td>
                        <td>{a.representative}</td>
                        <td>{a.phoneNumber}</td>
                        <td>{a.email}</td>
                        <TableActions zIndex={9 + k} options={[{ name: 'Izmeni', action: () => navigate(String(a.id)) }, { name: 'Obriši', action: () => handleDelete(a.id) }]} />

                    </tr>)}
                </tbody>

            </table>
        </div>
        {deleteModal.open && <ConfirmModal text="obrišete agenciju?" onYes={deleteModal.fn} closeModal={handleCloseModal} />}
    </div>
}