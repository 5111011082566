import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { Button } from "../../components/Button"
import { Input } from "../../components/Input"
import { addRentPeriod, editRentPeriod } from "../../features/rent-periods/rentPeriodsSlice"
import { AppDispatch } from "../../store"
import { changeState } from "../../utils/helpers"
import { IRentPeriodsState, rentPeriodsInitialState } from "./data"

export const FormModal = ({ type, value, setModal }: IFormModal) => {

    const [localState, setLocalState] = useState(type === 'adding' ? rentPeriodsInitialState : value)
    const dispatch = useDispatch<AppDispatch>()

    const handleCancel = () => {
        setModal({ open: false, name: '', type: 'adding' })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { valueAsNumber, name } = e.target
        changeState(setLocalState, name, valueAsNumber)
    }

    const handleSubmit = async () => {

        if (isNaN(localState.from) || isNaN(localState.to)) {
            toast.warn('Oba unosa moraju biti validna.')
            return
        }

        let resp;
        if (type === 'adding') {
            resp = await dispatch(addRentPeriod(localState))
        } else {
            resp = await dispatch(editRentPeriod(localState))
        }
        if (resp.meta.requestStatus === 'fulfilled') {
            handleCancel()
        }
    }


    return <div className="form-modal" >
        <h2>{type === 'adding' ? 'Dodavanje' : 'Izmena'} raspona</h2>
        <div className="form-modal__flex" >
            <Input label="Od" name="from" value={localState.from} type='number' handleChange={handleChange} />
            <p> __ </p>
            <Input label="Do" name="to" value={localState.to} type='number' handleChange={handleChange} />
        </div>
        <div className="form-modal__buttons" > <Button cancel text="Otkaži" onClick={handleCancel} /> <Button text="Sačuvaj" onClick={handleSubmit} />  </div>
    </div>
}

interface IFormModal {
    type: 'adding' | 'editing'
    value: IRentPeriodsState
    setModal: any
}